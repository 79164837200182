export default el => {

    const onClick = e => {
        el.hidden = true;
        const tags = e.currentTarget.parentNode.querySelectorAll('a[hidden]');
        if (!tags.length) {
            return;
        }
        tags.forEach(tag => {
            tag.hidden = false;
        });
        tags[0].focus();
    };

    el.addEventListener('click', onClick);

    return {
        destroy() {
            el.removeEventListener('click', onClick);
        }
    };

};
