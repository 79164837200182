import gsap from 'gsap';
import $ from '../../core/Dom';
import Dispatch from '../../core/Dispatch';
import { DOM_CHANGED, FONTS_LOADED } from '../../lib/events';
import Viewport from '../../core/Viewport';

export default el => {

    const $el = $(el);
    const tls = [];

    const initTexts = () => {
        tls.forEach(tl => {
            tl.kill();
        });
        $el.find('[data-text]')
            .each(text => {
                gsap.set(text, { clearProps: 'all' });
                text.classList.remove('ellipsis');
                const $text = $(text);
                const parentWidth = $text.parent().width();
                let extraPixels = $text.width() - parentWidth;
                text.classList.add('ellipsis');
                if (extraPixels <= 0) {
                    return;
                }
                extraPixels += 20;
                const pixelsPerSecond = 30;
                const duration = extraPixels / pixelsPerSecond;
                const tl = gsap.timeline({
                    repeat: -1,
                    delay: 2,
                    repeatDelay: 2,
                    yoyo: true
                })
                    .set(text, { maxWidth: 'none' }, 0)
                    .fromTo(text, { width: `${parentWidth}px` }, {
                        width: `${parentWidth + extraPixels}px`,
                        duration
                    }, 0)
                    .to(text, {
                        x: -extraPixels,
                        duration
                    }, 0);
                tls.push(tl);
            });
    };

    Dispatch.on(FONTS_LOADED, initTexts, true);

    const onResize = () => {
        initTexts();
    };

    Viewport.on('resize', onResize);

    initTexts();

    if (ENV !== 'production') {
        Dispatch.emit(DOM_CHANGED);
    }

    return {
        destroy() {
            Dispatch.off(FONTS_LOADED, initTexts);
            Viewport.off('resize', onResize);
        }
    };

};
