import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import {
    isKey,
    isTouch,
    lockTabbing,
    unlockTabbing
} from '../lib/helpers';

import Dispatch from '../core/Dispatch';

import { MODAL_CLOSE, MODAL_OPEN } from '../lib/events';

export default el => {

    const button = el.firstElementChild;
    const element = button.nextElementSibling;
    const popup = $(element)
        .find('[data-popup]')
        .get(0);

    const closeBtn = $(element)
        .find('button[data-close]')
        .get(0);

    let isExpanded = false;
    let isTabbingLocked = false;
    let mouseLeaveTimeout = null;

    const isSmall = () => ['m', 'mp', 'l', 'lp', 'xl'].indexOf(Viewport.breakpoint.name) === -1;

    const isElementFixed = () => window.getComputedStyle(element).position === 'fixed';

    const maybeLockTabbing = () => {
        if (isTabbingLocked || !isExpanded || !isElementFixed()) {
            return;
        }
        isTabbingLocked = true;
        lockTabbing(el, $(element)
            .find('button[data-close]')
            .get(0));
    };

    const maybeUnlockTabbing = () => {
        if (!isTabbingLocked) {
            return;
        }
        isTabbingLocked = false;
        unlockTabbing();
    };

    const positionPopup = () => {
        if (!isExpanded) {
            return;
        }
        gsap.set(popup, { clearProps: 'marginLeft' });
        if (!isSmall()) {
            const { left, width } = popup.getBoundingClientRect();
            // Get the first parent with overflow:hidden
            const overflowParent = $(el).parent('.overflow-hidden,.scrollable').get(0);
            const maxBoundRight = (overflowParent ? overflowParent.getBoundingClientRect().right : Viewport.width) - 40;
            let pixelsOver = (left + width) - maxBoundRight;
            if (pixelsOver < 0) {
                pixelsOver = 0;
            }
            gsap.set(popup, { marginLeft: -pixelsOver });
        }
    };

    let tl;

    const close = () => {
        if (!isExpanded) {
            return;
        }
        isExpanded = false;
        button.setAttribute('aria-expanded', 'false');
        if (tl) {
            tl.kill();
        }
        tl = gsap.timeline({
            onComplete() {
                tl = null;
                element.hidden = true;
            }
        })
            .to(element, {
                opacity: 0,
                duration: 0.3
            }, 0)
            .to(closeBtn, {
                scale: 0,
                duration: 0.3
            }, 0);
        if (isSmall()) {
            maybeUnlockTabbing();
            Dispatch.emit(MODAL_CLOSE);
        }
    };

    const open = () => {
        if (isExpanded) {
            return;
        }
        isExpanded = true;
        button.setAttribute('aria-expanded', 'true');
        element.hidden = false;
        if (isSmall()) {
            maybeLockTabbing();
            Dispatch.emit(MODAL_OPEN);
        }
        if (tl) {
            tl.kill();
        }
        tl = gsap.timeline()
            .fromTo(element, { opacity: 0 }, {
                opacity: 1,
                duration: 0.3
            }, 0)
            .fromTo(closeBtn, { scale: 0 }, {
                scale: 1,
                duration: 0.3
            }, 0)
            .fromTo(popup, { y: -10 }, {
                y: 0,
                duration: 0.5,
                ease: 'Expo.easeOut'
            }, 0);
        positionPopup();
    };

    const toggle = () => {
        if (isExpanded) {
            close();
        } else {
            open();
        }
    };

    const onBodyClick = e => {
        if (!isExpanded || e.target === button || button.contains(e.target) || e.target === popup || popup.contains(e.target)) {
            return;
        }
        close();
    };

    const onBodyKeyUp = e => {
        if (!isExpanded) {
            return;
        }
        if (e.key === 'Escape' || e.keyCode === 27) {
            close();
        }
    };

    const onBtnClick = e => {
        if ((!isSmall() && !isTouch()) || e.target.hasAttribute('data-blocker')) {
            return;
        }
        toggle();
    };

    const onBtnMouseOver = () => {
        if (mouseLeaveTimeout) {
            clearTimeout(mouseLeaveTimeout);
            mouseLeaveTimeout = null;
        }
        if (isSmall()) {
            return;
        }
        open();
    };

    const onMouseLeave = () => {
        if (mouseLeaveTimeout) {
            clearTimeout(mouseLeaveTimeout);
            mouseLeaveTimeout = null;
        }
        if (isSmall()) {
            return;
        }
        mouseLeaveTimeout = setTimeout(() => {
            close();
        }, 10);
    };

    const $body = $('body');

    $body
        .on('click', onBodyClick)
        .on('keyup', onBodyKeyUp);

    $(button)
        .on('click', onBtnClick)
        .on('mouseover', onBtnMouseOver)
        .on('keyup', e => {
            if (isKey(e, 'Enter')) {
                toggle();
            }
        })
        .on('keydown', e => {
            if (isKey(e, 'Space')) {
                e.preventDefault();
                toggle();
            }
        });

    $(el)
        .on('mouseleave', onMouseLeave);

    const onBreakpoint = () => {
        if (!isExpanded) {
            return;
        }
        if (!isElementFixed()) {
            maybeUnlockTabbing();
        } else {
            maybeLockTabbing();
        }
    };

    Viewport.on('breakpoint', onBreakpoint);

    const onResize = () => {
        positionPopup();
    };

    Viewport.on('resize', onResize);

    return {
        destroy() {
            close();
            $(button)
                .off('click keyup keydown mouseover');
            $(el)
                .off('mouseleave');
            $body.off('click', onBodyClick);
            $body.off('keyup', onBodyKeyUp);
            Viewport.off('breakpoint', onBreakpoint);
            Viewport.off('resize', onResize);
            if (mouseLeaveTimeout) {
                clearTimeout(mouseLeaveTimeout);
            }
        }
    };

};
