import Flickity from 'flickity';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { DOM_CHANGED } from '../lib/events';
import { isTouch } from '../lib/helpers';

export default (el, options = {}) => {

    const $el = $(el);
    const slider = $el.find('[data-slider]').get(0) || el.firstElementChild;

    if (slider.children.length < 2) {
        return null;
    }

    const nextArrow = $el.find('[data-arrow="next"]').get(0);
    const prevArrow = $el.find('[data-arrow="prev"]').get(0);

    let flkty;
    let hasInteracted = false;

    const updateCaption = () => {
        $el.find('[data-caption]').last().html(flkty.selectedCell.element.dataset.caption || '');
    };

    const updateCounter = () => {
        const { selectedIndex } = flkty;
        $el.find('[data-index]').each(node => {
            node.textContent = selectedIndex + 1;
        });
    };

    const updateArrowColor = () => {
        const { selectedIndex } = flkty;
        const image = $el.find(`[data-slide="${selectedIndex}"] img`).get(0);
        if (!image) {
            return;
        }
        const { dark } = image.dataset;
        const $arrowSpans = $([nextArrow, prevArrow]).find('span');
        if (dark) {
            $arrowSpans.removeClass('text-black');
            $arrowSpans.addClass('text-white drop-shadow-cursor');
        } else {
            $arrowSpans.removeClass('text-white drop-shadow-cursor');
            $arrowSpans.addClass('text-black');
        }
    };

    const preloadNext = () => {
        const $nextSlide = $(flkty.selectedCell.element.nextElementSibling);
        if (!$nextSlide.length) {
            return;
        }
        $nextSlide.find('img[draggable="false"]:not([class*="lazy"])').eq(0).addClass('lazyload lazypreload');
    };

    const destroyFlickity = () => {
        if (!flkty) {
            return;
        }
        flkty.destroy();
        flkty = null;
    };

    flkty = new Flickity(slider, {
        contain: true,
        dragThreshold: 10,
        cellAlign: 'left',
        prevNextButtons: false,
        pageDots: false,
        freeScroll: false,
        freeScrollFriction: 0.045,
        selectedAttraction: 0.015,
        friction: 0.22,
        resize: true,
        adaptiveHeight: false,
        setGallerySize: true,
        wrapAround: true,
        accessibility: true,
        autoPlay: false,
        percentPosition: false,
        on: {
            dragStart() {
                hasInteracted = true;
                document.ontouchmove = e => e.preventDefault();
            },
            dragEnd() {
                document.ontouchmove = () => true;
            },
            staticClick(e) {
                hasInteracted = true;
                const { left, width } = slider.getBoundingClientRect();
                const x = e.pageX - left;
                if (x >= (width / 2)) {
                    this.next();
                } else {
                    this.previous();
                }
            },
            change() {
                hasInteracted = true;
                updateCounter();
                updateCaption();
                updateArrowColor();
                preloadNext();
            }
        },
        ...options
    });

    $el.data('_slideshow', flkty);

    let observer = new IntersectionObserver(([{ isIntersecting }]) => {
        if (!isIntersecting) {
            return;
        }
        preloadNext();
        observer.disconnect();
        observer = null;
    });

    observer.observe(el);

    const onMouseMove = e => {
        if (isTouch()) {
            return;
        }
        const { pageX } = e;
        const { left, width } = slider.getBoundingClientRect();

        if ((pageX - left) >= (width / 2)) {
            nextArrow.hidden = false;
            prevArrow.hidden = true;
        } else {
            nextArrow.hidden = true;
            prevArrow.hidden = false;
        }

    };

    $(slider).on('mousemove', onMouseMove);

    if (ENV !== 'production') {
        Dispatch.emit(DOM_CHANGED);
    }

    return {
        destroy() {
            destroyFlickity();
            if (observer) {
                observer.disconnect();
                observer = null;
            }
            $(slider.parentNode).off('mousemove', onMouseMove);
        }
    };

};
