import gsap from 'gsap';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import {
    getModalIframeType,
    isKey,
    isKeyCombo,
    isModalIframe,
    lockScroll,
    lockTabbing, MESSAGE_CLOSE, MESSAGE_LOAD,
    messageParent,
    unlockScroll,
    unlockTabbing
} from '../lib/helpers';
import { AJAX_LOAD_END, AJAX_LOAD_START } from '../lib/events';

const renderModal = () => `
    <div class="fixed full z-top pointer-events-none">
        <div class="absolute w-full h-[4px] left-0 top-0 bg-purple z-2 pointer-events-none opacity-0" data-loader></div>
        <div class="absolute full bg-white opacity-0 z-1" data-wrapper></div>
    </div>
`;

export default {

    $modal: null,
    hasInitialised: false,

    init() {

        if (this.hasInitialised) {
            return;
        }

        this.url = window.location.href;
        this.title = document.title;

        this.hasInitialised = true;

        const $body = $(document.body);

        this.onBodyKeyUp = this.onBodyKeyUp.bind(this);
        this.onMessage = this.onMessage.bind(this);

        $body.on('keyup', this.onBodyKeyUp);

        const isIframed = isModalIframe();

        if (isIframed) { // We're loaded in a modal iframe

            messageParent(MESSAGE_LOAD);

            // Don't allow in-iframe navigation for most things
            $body.on('click', 'a:not([target]):not([data-modal]):not([data-modalclosebtn]):not([href^="#"]:not([role="button"]):not([href=""])', e => {
                e.preventDefault();
                window.parent.location.href = e.triggerTarget.href;
            });

            // Hook up close buttons
            $('[data-modalclosebtn]').attr({ role: 'button' });
            $body.on('click', '[data-modalclosebtn]', e => {
                e.preventDefault();
                messageParent(MESSAGE_CLOSE);
            });

            // Set focus to the modal close button on pageload
            $('[data-modalclosebtn]').get(0).focus();

            if ('scrollRestoration' in window.history) {
                window.history.scrollRestoration = 'manual';
            }

        }

        $body.on('click', 'a[data-modal][href]', e => {
            if (isKeyCombo(e)) {
                return true;
            }
            const type = e.triggerTarget.dataset.modal || '';
            if (isModalIframe() && getModalIframeType() === type) {
                // If we're already loaded in an iframe w/ the same "type", then allow in-frame navigation
                // If not, we're going inception style. Iframe inside iframes baby
                return true;
            }
            e.preventDefault();
            e.stopImmediatePropagation();
            const { href: url } = e.triggerTarget;
            const title = e.triggerTarget.dataset.title || '';
            this.open(url, title, type);
            return false;
        });

        const currentPath = window.location.pathname;

        window.addEventListener('popstate', e => {
            const { state } = e;
            if (state && state.modal) {
                this.open(window.location.href, state.modal.title);
            } else if (this.$modal) {
                this.close();
            } else if (currentPath !== window.location.pathname) {
                window.location.reload();
            }
        });

        window.addEventListener('message', this.onMessage);

    },

    open(url, title, type) {

        if (!this.hasInitialised) {
            window.location.href = url;
            return;
        }

        if (!this.$modal) {

            // Create modal
            this.$modal = $(renderModal(title));
            $('body').append(this.$modal);

            this.wrapper = this.$modal.find('[data-wrapper]')
                .get(0);

            Dispatch.emit(AJAX_LOAD_START);

            // Play intro transition
            this.createIframe(url, title, type);

            lockTabbing(this.$modal.get(0), this.iframe);

            gsap.timeline()
                .fromTo(this.wrapper, { opacity: 0 }, {
                    opacity: 1,
                    duration: 0.3,
                    onComplete() {
                        lockScroll();
                    }
                }, 0);

        } else {

            this.createIframe(url, title, type);

        }

        this.pushState(url, title);

    },

    close() {

        if (!this.$modal) {
            return;
        }

        const { $modal } = this;
        this.$modal = null;
        this.wrapper = null;

        this.pushState(this.url, this.title, false);

        gsap.timeline({
            onComplete: () => {
                this.destroyIframe();
                $modal.remove();
                unlockTabbing();
            }
        })
            .set([$modal.get(0), this.iframe], { pointerEvents: 'none' })
            .to(this.iframe, {
                opacity: 0,
                duration: 0.3,
                onComplete() {
                    unlockScroll();
                }
            }, 0)
            .to($modal.get(0), {
                opacity: 0,
                duration: 0.5,
                ease: 'Cubic.easeIn'
            }, 0);

        Dispatch.emit(AJAX_LOAD_END);

    },

    destroyIframe() {
        if (!this.iframe) {
            return;
        }
        this.iframe.parentElement.removeChild(this.iframe);
        this.iframe = null;
    },

    createIframe(url, title = '', type = null) {
        this.destroyIframe();
        if (!this.wrapper) {
            return;
        }
        this.iframe = document.createElement('OBJECT');
        this.iframe.className = 'block absolute full scrollable z-1 pointer-events-auto';
        this.iframe.setAttribute('title', title);
        this.iframe.setAttribute('data-modal', type || true);
        this.iframe.data = url;
        this.wrapper.appendChild(this.iframe);
    },

    pushState(url, title = '', asModal = true) {
        if (title !== document.title) {
            document.title = title;
        }
        if (url !== window.location.href) {
            const state = asModal ? { modal: JSON.stringify({ title }) } : null;
            window.history.pushState(state, '', url);
        }
    },

    replaceState(url, title = '', asModal = true) {
        if (title !== document.title) {
            document.title = title;
        }
        if (url !== window.location.href) {
            const state = asModal ? { modal: JSON.stringify({ title }) } : null;
            window.history.replaceState(state, '', url);
        }
    },

    onBodyKeyUp(e) {
        if (isKey(e, 'Escape')) {
            if (isModalIframe()) {
                messageParent(MESSAGE_CLOSE);
            } else {
                this.close();
            }
        }
    },

    onMessage(e) {
        if (e.data === 'close') {
            this.close();
        } else if (e.data === 'load') {
            if (!this.$modal) { // It's already been closed!
                return;
            }
            const { href } = this.iframe.contentWindow.window.location;
            const { title } = this.iframe.contentWindow.document;
            this.replaceState(href, title);
        } else if (e.data === 'navigation') {
            Dispatch.emit(AJAX_LOAD_START);
        } if (e.data === 'ready') {
            Dispatch.emit(AJAX_LOAD_END);
        }
    }

};
