import throttle from 'lodash/throttle';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import { areRectsIntersecting } from '../lib/helpers';
import { DOM_CHANGED } from '../lib/events';

export default () => {

    const intersectingDarkNodes = new Set();

    const targets = $('[data-lightordark]').get();

    const maybeInvertBurgerBars = () => {

        const nodes = Array.from(intersectingDarkNodes).reverse();
        const visibleTargets = targets.filter(target => !!target.offsetParent);

        for (let i = 0; i < visibleTargets.length; i += 1) {
            const target = visibleTargets[i];
            const {
                left,
                right,
                top,
                bottom,
                height
            } = target.getBoundingClientRect();
            const targetRect = {
                left,
                right,
                top: top + (height * 0.5),
                bottom: bottom - (height * 0.5)
            };
            let hasIntersectingNode;
            for (let j = 0; j < nodes.length; j += 1) {
                const node = nodes[j];
                const nodeRect = node.getBoundingClientRect();
                hasIntersectingNode = areRectsIntersecting(nodeRect, targetRect);
                if (hasIntersectingNode) {
                    if (!!node.dataset.dark) {
                        target.classList.remove('text-black');
                        target.classList.add('text-white');
                    } else {
                        target.classList.remove('text-white');
                        target.classList.add('text-black');
                    }
                    break;
                }
            }
            if (!hasIntersectingNode) {
                target.classList.remove('text-white');
                target.classList.add('text-black');
            }
        }

    };

    const maybeInvertTargetsDeferred = throttle(maybeInvertBurgerBars, 10, {
        leading: true,
        trailing: true
    });

    const darkNodesObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            const {
                isIntersecting,
                target
            } = entry;
            if (isIntersecting) {
                intersectingDarkNodes.add(target);
            } else {
                intersectingDarkNodes.delete(target);
            }
            maybeInvertTargetsDeferred();
        });
    }, {
        rootMargin: '0px -40px 0px -40px',
        threshold: 0
    });

    $('[data-dark]')
        .each(node => {
            darkNodesObserver.observe(node);
        });

    const onScroll = () => {
        maybeInvertTargetsDeferred();
    };

    const onResize = () => {
        setTimeout(onScroll, 0);
    };

    let mutationObserver;
    if (window.MutationObserver) {
        mutationObserver = new MutationObserver(() => {
            maybeInvertTargetsDeferred();
        });
        mutationObserver.observe(document.body, {
            attributes: false,
            childList: true,
            subtree: true
        });
    }

    Dispatch.on(DOM_CHANGED, () => {
        maybeInvertTargetsDeferred();
    });

    Viewport.on('scroll', onScroll);
    Viewport.on('resize', onResize);

    $('body').on('focusin', maybeInvertTargetsDeferred);

};
