import serialize from 'form-serialize';
import $ from '../../core/Dom';
import superagent from '../../core/request';
import { setHtml, isKey } from '../../lib/helpers';
import Dispatch from '../../core/Dispatch';
import { AJAX_LOAD_END, AJAX_LOAD_START, DOM_CHANGED } from '../../lib/events';

export default el => {

    const $el = $(el);
    const form = $el.find('form').get(0);

    let isSubmitting = false;
    let prevValue = '';

    const updateHtml = html => {
        const $html = $('<div/>').html(html).find('#search').eq(0);
        // Update search form
        const $form = $(form);
        const $newForm = $html.find('form').eq(0);
        if ($form.html() !== $newForm.html()) {
            $form.html($newForm.html());
            $form.find('input[type="search"]').focus();
        }
        // Update results
        const $resultsContainer = $el.find('[data-results]').eq(0);
        const $newResultsContainer = $html.find('[data-results]').eq(0);
        if ($resultsContainer.html() !== $newResultsContainer.html()) {
            setHtml($resultsContainer.get(0), $newResultsContainer.html());
        }
        Dispatch.emit(DOM_CHANGED);
    };

    const submitForm = () => {

        if (isSubmitting) {
            return;
        }

        isSubmitting = true;

        // Update query string
        let url = window.location.href.split('?')[0];
        const query = serialize(form);
        if (query) {
            url += `?${query}`;
        }
        window.history.pushState(null, '', url);

        Dispatch.emit(AJAX_LOAD_START);

        superagent
            .get(window.location.href)
            .type('form')
            .set('Accept', 'text/html')
            .query(serialize(form))
            .then(({ status, text }) => {
                if (status !== 200 || !text) {
                    throw new Error();
                }
                updateHtml(text);
                prevValue = '';
            })
            .catch(error => {
                console.warn(error);
            })
            .then(() => {
                isSubmitting = false;
                Dispatch.emit(AJAX_LOAD_END);
            });
    };

    const removeTerm = term => {
        if (!term) {
            return;
        }
        $(form).find(`input[type="hidden"][name="q[]"][value="${term.textContent}"]`).remove();
        $(term).remove();
        $(form).find('input').get(0).focus();
        setTimeout(submitForm, 0);
    };

    const onSuggestedTermClick = e => {
        e.preventDefault();
        e.stopImmediatePropagation();
        const { triggerTarget: term } = e;
        $(form).append(`<input type="hidden" name="q[]" value="${term.dataset.suggestedTerm}" />`);
        $(form).find('input').get(0).focus();
        setTimeout(submitForm, 0);
    };

    const onTermClick = e => {
        e.preventDefault();
        e.stopImmediatePropagation();
        removeTerm(e.triggerTarget);
    };

    const onInputKeyUp = e => {
        const { triggerTarget: input } = e;
        if (!prevValue && isKey(e, 'Backspace')) {
            removeTerm($el.find('a[data-term]').last().get(0));
        }
        prevValue = input.value;
    };

    const onSubmit = e => {
        e.preventDefault();
        setTimeout(submitForm, 0);
    };

    $(form)
        .on('keyup', 'input[type="search"]', onInputKeyUp)
        .on('submit', onSubmit);

    $el
        .on('click', '[data-suggested-term]', onSuggestedTermClick)
        .on('click', '[data-term]', onTermClick);

    if (ENV !== 'production') {
        Dispatch.emit(DOM_CHANGED);
    }

    return {
        destroy() {
            $el.off('click');
            $(form).off('keyup submit');
        }
    };

};
