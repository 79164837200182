import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import { getMaxScroll, getScrollbarWidth } from '../lib/helpers';
import Dispatch from '../core/Dispatch';
import {
    DOM_CHANGED, FONTS_LOADED,
    LOGO_APPEAR,
    MENU_CLOSE,
    MENU_OPEN,
    MODAL_OPEN,
    SCROLL_LOCKED,
    SCROLL_UNLOCKED, HEADER_VISIBLE, HEADER_HIDDEN, MODAL_CLOSE
} from '../lib/events';

export default el => {

    const $el = $(el);
    const inner = el.firstElementChild;
    const menuContainer = $el.find('[data-menu-container]').get(0);

    const isFixed = el.classList.contains('fixed');

    let {
        scrollTop: prevScrollTop,
        width: viewW,
        height: viewH
    } = Viewport;

    let height = $(inner)
        .height();

    let maxScroll = getMaxScroll();

    let isSticky = isFixed;
    let isHidden = false;
    let menuIsOpen = false;
    let hasIntroed = false;
    let hasShownLogo = false;

    const logo = $el.find('[data-logo]').get(0);
    const toggle = $el.find('[data-toggle]').get(0);
    const modalCloseBtn = $el.find('[data-modalclosebtn]').get(0);

    const logoIntro = logo ? gsap.timeline({
        paused: true,
        onStart() {
            Dispatch.emit(LOGO_APPEAR);
        }
    })
        .fromTo(logo, { opacity: 0 }, {
            opacity: 1,
            duration: 0.75
        }, 0) : null;

    const intro = gsap.timeline({ paused: true })
        .fromTo(el, { opacity: 0 }, { opacity: 1, duration: 0.5, ease: 'Cubic.easeIn' }, 0);

    const maybeIntro = () => {
        if (hasIntroed || isHidden) {
            return;
        }
        hasIntroed = true;
        if (!isSticky && toggle && toggle.offsetParent) {
            const dots = $(toggle).find('[data-toggle-dots]').get(0).children;
            const { width } = toggle.getBoundingClientRect();
            const { width: dotWidth } = dots[0].getBoundingClientRect();
            $(dots)
                .css({ transition: 'none' });
            intro
                .fromTo(toggle, { scale: 0 }, {
                    scale: 1,
                    duration: 0.5,
                    ease: 'Back.easeOut'
                }, 'toggle')
                .fromTo(dots[0], { x: (width * 0.3) - (dotWidth * 0.3) }, {
                    x: 0,
                    duration: 0.5,
                    ease: 'Back.easeOut'
                }, 'toggle+=0.25')
                .fromTo(dots[2], { x: -((width * 0.3) - (dotWidth * 0.3)) }, {
                    x: 0,
                    duration: 0.5,
                    ease: 'Back.easeOut'
                }, 'toggle+=0.25')
                .set([dots, toggle], { clearProps: 'all' });
        }
        intro.play();
    };

    const maybeShowLogo = () => {
        if (hasShownLogo || isHidden) {
            return;
        }
        hasShownLogo = true;
        if (logoIntro) {
            logoIntro.play();
        }
    };

    const show = (tween = true) => {
        if (!isHidden) {
            return;
        }
        isHidden = false;
        gsap.killTweensOf(inner);
        const btn = toggle || modalCloseBtn;
        if (btn) {
            gsap.set(btn, { clearProps: 'pointerEvents' });
        }
        if (tween) {
            gsap.timeline()
                .to(btn, { opacity: 1, duration: 0.15 }, 0)
                .to(inner, {
                    yPercent: 0,
                    duration: 0.3
                }, 0);
        } else {
            gsap.set(btn, { opacity: 1 });
            gsap.set(inner, { yPercent: 0 });
        }
        maybeIntro();
        maybeShowLogo();
        Dispatch.emit(HEADER_VISIBLE);
    };

    const hide = (tween = true) => {
        if (isHidden) {
            return;
        }
        isHidden = true;
        gsap.killTweensOf(inner);
        if (tween) {
            gsap.to(inner, {
                yPercent: -100,
                duration: 0.3
            });
        } else {
            gsap.set(inner, { yPercent: -100 });
        }
        Dispatch.emit(HEADER_HIDDEN);
    };

    const stick = () => {
        if (isSticky || isFixed) {
            return;
        }
        isSticky = true;
        inner.classList.replace('absolute', 'fixed');
    };

    const unstick = () => {
        if (!isSticky || isFixed) {
            return;
        }
        isSticky = false;
        inner.classList.replace('fixed', 'absolute');
    };

    const onScroll = (tween = true, force = false) => {
        const { scrollTop } = Viewport;
        let direction = 'up';
        if (prevScrollTop) {
            direction = scrollTop > prevScrollTop ? 'down' : 'up';
        }
        // Make sticky?
        const top = Math.round(scrollTop);
        if (isSticky) {
            if (top <= 0) {
                unstick();
                show(false);
                return;
            }
            if (menuIsOpen) {
                show(false);
            }
            if (!force && Math.abs(top - prevScrollTop) <= 20) {
                return;
            }
            const threshold = isFixed ? (viewH / 3) : height;
            if (direction === 'down' && top >= threshold) {
                hide(tween);
            } else if (direction === 'up' && scrollTop < maxScroll) {
                show(tween);
            }
        } else if (top >= height) {
            stick();
            hide(false);
            return;
        }
        prevScrollTop = scrollTop;
    };

    const onResize = (updateScroll = true, force = false) => {
        if (!force && Viewport.width === viewW && Math.abs(Viewport.height - viewH) < 150 && getMaxScroll() === maxScroll) {
            return;
        }
        viewW = Viewport.width;
        viewH = Viewport.height;
        height = $(inner)
            .height();
        maxScroll = getMaxScroll();
        if (updateScroll) {
            onScroll();
        }
    };

    Viewport.on('scroll', onScroll);
    Viewport.on('resize', onResize);

    onResize(false, true);
    onScroll(false, true);

    const onScrollLocked = () => {
        if (!getScrollbarWidth() || !menuContainer) {
            return;
        }
        menuContainer.style.paddingRight = `${getScrollbarWidth()}px`;
    };

    const onScrollUnlocked = () => {
        if (menuContainer) {
            menuContainer.style.paddingRight = '';
        }
    };

    Dispatch.on(SCROLL_LOCKED, onScrollLocked);
    Dispatch.on(SCROLL_UNLOCKED, onScrollUnlocked);

    let mutationObserver;
    if (window.MutationObserver) {
        mutationObserver = new MutationObserver(() => {
            onResize();
        });
        mutationObserver.observe(document.body, {
            attributes: false,
            childList: true,
            subtree: true
        });
    }

    Dispatch.on(DOM_CHANGED, onResize);

    const onModalOpen = (event, { type = '' } = {}) => {
        if (['menu', 'frame'].indexOf(type) > -1) {
            return;
        }
        const btn = toggle || modalCloseBtn;
        if (!btn) {
            return;
        }
        gsap.timeline()
            .set(btn, { pointerEvents: 'none' })
            .to(btn, { scale: 0, duration: 0.3 });
    };

    const onModalClose = () => {
        const btn = toggle || modalCloseBtn;
        if (!btn) {
            return;
        }
        gsap.timeline()
            .set(btn, { clearProps: 'pointerEvents' })
            .to(btn, { scale: 1, duration: 0.3 })
            .set(btn, { clearProps: 'transform' });
    };

    Dispatch.on(MODAL_OPEN, onModalOpen);
    Dispatch.on(MODAL_CLOSE, onModalClose);

    const onMenuOpen = () => {
        menuIsOpen = true;
        show(false);
    };

    const onMenuClose = () => {
        setTimeout(() => {
            menuIsOpen = false;
        }, 100);
    };

    Dispatch.on(MENU_OPEN, onMenuOpen);
    Dispatch.on(MENU_CLOSE, onMenuClose);

    $(el)
        .on('focusin', 'a,button', show);

    setTimeout(maybeIntro, 0);

    Dispatch.on(FONTS_LOADED, maybeShowLogo, true);

    return {
        destroy() {
            $(el)
                .off('focusin');
            Viewport.off('scroll', onScroll);
            Viewport.off('resize', onResize);
            Dispatch.off(SCROLL_LOCKED, onScrollLocked);
            Dispatch.off(SCROLL_UNLOCKED, onScrollUnlocked);
            Dispatch.off(DOM_CHANGED, onResize);
            Dispatch.off(MODAL_OPEN, onModalOpen);
            Dispatch.off(MODAL_CLOSE, onModalClose);
            Dispatch.off(FONTS_LOADED, maybeShowLogo);
            if (mutationObserver) {
                mutationObserver.disconnect();
                mutationObserver = null;
            }
        }
    };

};
