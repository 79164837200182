import gsap from 'gsap';
import $ from '../../core/Dom';
import Viewport from '../../core/Viewport';
import Dispatch from '../../core/Dispatch';
import { SCROLL_LOCKED, SCROLL_UNLOCKED } from '../../lib/events';
import { getScrollbarWidth } from '../../lib/helpers';

export default el => {

    const picture = $(el).find('picture').get(0);

    const timeline = gsap.timeline({ paused: true })
        .to(picture, { yPercent: -5, ease: 'none', duration: 1 }, 0);

    let { height } = picture.getBoundingClientRect();

    let isVisible = true;

    const onScroll = () => {
        const { scrollTop } = Viewport;
        const makeVisible = scrollTop <= height;
        if (isVisible && !makeVisible) {
            el.style.visibility = 'hidden';
            isVisible = false;
        } else if (!isVisible && makeVisible) {
            el.style.visibility = '';
            height = picture.getBoundingClientRect().height;
            isVisible = true;
        }
        if (isVisible) {
            timeline.progress(Math.min(Math.max(Viewport.scrollTop / height, 0), 1), true);
        }
    };

    const onResize = () => {
        if (!isVisible) {
            return;
        }
        height = picture.getBoundingClientRect().height;
        onScroll();
    };

    Viewport.on('scroll', onScroll);
    Viewport.on('resize', onResize);

    const onScrollLocked = () => {
        if (!getScrollbarWidth()) {
            return;
        }
        el.firstElementChild.style.paddingRight = `${getScrollbarWidth()}px`;
    };

    const onScrollUnlocked = () => {
        if (!getScrollbarWidth()) {
            return;
        }
        el.firstElementChild.style.paddingRight = '';
    };

    Dispatch.on(SCROLL_LOCKED, onScrollLocked);
    Dispatch.on(SCROLL_UNLOCKED, onScrollUnlocked);

    const overlay = $(el).find('[data-overlay]').get(0);
    if (overlay) {
        const tl = gsap.timeline({ paused: true }).fromTo(overlay, { opacity: 0 }, { opacity: 1, duration: 0.75, ease: 'Quad.easeIn' });
        if ($(picture).find('img.lazyloaded').length) {
            tl.play();
        } else {
            const onLazyloaded = e => {
                picture.removeEventListener('lazyloaded', onLazyloaded);
                tl.play();
            };
            picture.addEventListener('lazyloaded', onLazyloaded);
        }
    }

    onScroll();

    return {
        destroy() {
            Dispatch.off(SCROLL_LOCKED, onScrollLocked);
            Dispatch.off(SCROLL_UNLOCKED, onScrollUnlocked);
            Viewport.off('scroll', onScroll);
            Viewport.off('resize', onResize);
        }
    };

};
