import gsap from 'gsap';
import $ from '../core/Dom';

export default el => {

    const $el = $(el);
    const $audio = $el.find('audio').eq(0);
    const $progress = $el.find('[data-progress]').eq(0);

    let isPlaying = false;
    let progressInterval = null;

    const setProgress = progress => {
        if (!$progress.length) {
            return;
        }
        gsap.set($progress.get(0), { scaleX: progress });
    };

    const setPlaying = value => {
        if (value === isPlaying) {
            return;
        }
        isPlaying = value;
        if (progressInterval) {
            clearInterval(progressInterval);
            progressInterval = null;
        }
        if (isPlaying) {
            progressInterval = setInterval(() => {
                const { currentTime, duration } = $audio.get(0);
                if (!currentTime || !duration) {
                    setProgress(0);
                } else {
                    setProgress(currentTime / duration);
                }
            }, 10);
            $el.addClass('is-playing');
        } else {
            $el.removeClass('is-playing');
        }
    };

    const play = () => {
        if (isPlaying) {
            return;
        }
        setPlaying(true);
        $audio.get(0).play();
    };

    const pause = () => {
        if (!isPlaying) {
            return;
        }
        setPlaying(false);
        $audio.get(0).pause();
    };

    const togglePlay = () => {
        if (!isPlaying) {
            play();
        } else {
            pause();
        }
    };

    const onClick = e => {
        e.preventDefault();
        togglePlay();
    };

    const onPlay = () => {
        setPlaying(true);
    };

    const onPause = () => {
        setPlaying(false);
    };

    const onComplete = () => {
        setProgress(0);
        setPlaying(false);
    };

    $audio.on('playing', onPlay);
    $audio.on('pause', onPause);
    $audio.on('ended', onComplete);

    $el.on('click', onClick);

    let observer = new IntersectionObserver(([{ isIntersecting }]) => {
        if (!isIntersecting) {
            return;
        }
        $audio.attr('src', $audio.data('src'));
        observer.disconnect();
        observer = null;
    });

    observer.observe(el);

    return {
        destroy() {
            $el.off('click');
            pause();
            if (observer) {
                observer.disconnect();
            }
        }
    };

};
