import gsap from 'gsap';
import Draggable from 'gsap/Draggable';
import InertiaPlugin from 'gsap/InertiaPlugin';

import Dispatch from '../core/Dispatch';
import { DOM_CHANGED } from '../lib/events';
import Viewport from '../core/Viewport';

gsap.registerPlugin(InertiaPlugin);
gsap.registerPlugin(Draggable);

export default el => {

    let draggable = Draggable.create(el.firstElementChild, {
        bounds: el,
        type: 'x',
        inertia: true,
        allowNativeTouchScrolling: true
    })[0];

    const onResize = () => {
        const { width } = el.getBoundingClientRect();
        const { width: innerWidth } = el.firstElementChild.getBoundingClientRect();
        if (innerWidth <= width) {
            draggable.disable();
        } else {
            draggable.enable();
        }
    };

    Viewport.on('resize', onResize);

    onResize();

    if (ENV !== 'production') {
        Dispatch.emit(DOM_CHANGED);
    }

    return {
        destroy() {
            draggable.kill();
            draggable = null;
            Viewport.off('resize', onResize);
        }
    };

};
