import serialize from 'form-serialize';
import $ from '../core/Dom';
import superagent from '../core/request';

export default form => {

    const $form = $(form);
    const namespace = $form.find('input[name="namespace"]').val();

    let isSubmitting = false;

    const showErrors = ({ errorMessage = '', errors = [] }) => {

        const errorDiv = $form.find('[data-error]').get(0);
        if (!errorDiv) {
            return;
        }

        let firstErrorInput = null;
        const errorTexts = [];

        if (errorMessage) {
            errorTexts.push(errorMessage);
        }
        if (errors) {
            Object.keys(errors).forEach(name => {
                const id = `#${namespace}-${name}`;
                firstErrorInput = firstErrorInput || $form.find(id).get(0);
                errorTexts.push(`<a href="${id}" data-noanchor>${errors[name]}</a>`);
            });
        }

        if (errorTexts.length) {
            $(errorDiv).html(errorTexts.join('<br/>'));
        } else {
            $(errorDiv).html('Something went wrong! Please try again later.');
        }

        errorDiv.hidden = false;

        if (firstErrorInput) {
            try {
                firstErrorInput.focus();
            } catch (error) {
                console.warn(error);
            }
        }

    };

    const clearErrors = () => {

        const errorDiv = $form.find('[data-error]').get(0);
        if (!errorDiv) {
            return;
        }

        errorDiv.hidden = true;
        $(errorDiv).html('');

    };

    const showReceipt = () => {
        const receiptDiv = $form.find('[data-receipt]').get(0);
        if (!receiptDiv) {
            return;
        }
        $(receiptDiv).css({ display: 'block' });
        $form.find('input:not([type="hidden"])').each(input => {
            if (input.getAttribute('type') === 'checkbox') {
                input.checked = false;
            } else {
                input.value = '';
            }
        });
        setTimeout(() => {
            $(receiptDiv).css({ display: 'none' });
        }, 4000);

    };

    const submit = () => {

        if (isSubmitting) {
            return;
        }

        isSubmitting = true;
        $form.addClass('js-is-submitting');

        const data = serialize($form.get(0), { hash: false });

        superagent
            .post(window.location.href)
            .send(data)
            .set('Accept', 'application/json')
            .then(({ status, body }) => {
                if (status !== 200) {
                    throw new Error();
                }
                clearErrors();
                showReceipt();
            })
            .catch(error => {
                showErrors(error.response.body || {});
            })
            .then(() => {
                isSubmitting = false;
                $form.removeClass('js-is-submitting');
            });
    };

    $form.on('submit', e => {
        e.preventDefault();
        submit();
    });

    return {
        destroy() {
            $form.off('submit');
        }
    };

};
