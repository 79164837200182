import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import { SCROLL_LOCKED, SCROLL_UNLOCKED } from './events';
import Components from '../core/Components';

gsap.registerPlugin(ScrollToPlugin);

let isScrollingTo;

export const reduceMotion = () => window.matchMedia('(prefers-reduced-motion: reduce), (update: slow)').matches;

export const getMaxScroll = () => (Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight) - Viewport.height);

export const scrollTo = (value, opts = {}) => {

    if (isScrollingTo === value) {
        return;
    }

    isScrollingTo = value;

    let scrollMargin;
    if (value instanceof Element) {
        scrollMargin = parseInt(window.getComputedStyle(value)
            .getPropertyValue('scroll-margin-top') || 0, 10);
    } else {
        scrollMargin = 0;
    }

    const duration = reduceMotion() ? 0 : 1;

    gsap.to(window, {
        duration,
        ease: 'Cubic.easeInOut',
        ...opts,
        scrollTo: {
            y: value,
            offsetY: scrollMargin,
            autoKill: true,
            onAutoKill() {
                isScrollingTo = null;
            }
        },
        onComplete() {
            isScrollingTo = null;
        }
    });
};

let scrollbarWidth = null;

export const getScrollbarWidth = () => {
    if (scrollbarWidth === null) {
        if (document.documentElement.classList.contains('overflow-hidden')) {
            document.documentElement.style.setProperty('--scrollbar-width', '0px');
        }
        scrollbarWidth = parseInt(getComputedStyle(document.documentElement)
            .getPropertyValue('--scrollbar-width'), 10);
    }
    return scrollbarWidth;
};

let scrollLocked = false;

export const lockScroll = () => {
    if (scrollLocked) {
        return;
    }
    scrollLocked = true;
    Viewport.lockScrolling();
    if (getScrollbarWidth()) {
        document.getElementById('main').style.paddingRight = `${getScrollbarWidth()}px`;
    }
    Dispatch.emit(SCROLL_LOCKED);
};

export const unlockScroll = () => {
    if (!scrollLocked) {
        return;
    }
    scrollLocked = false;
    Viewport.releaseScrolling();
    if (getScrollbarWidth()) {
        document.getElementById('main').style.paddingRight = '';
    }
    Dispatch.emit(SCROLL_UNLOCKED);
};

export const isScrollLocked = () => scrollLocked;

let isTabbingLocked = false;
let focusedElementBeforeTabbingLocked = null;

export const lockTabbing = (el, focusEl) => {
    if (isTabbingLocked) {
        return;
    }
    isTabbingLocked = true;
    focusedElementBeforeTabbingLocked = document.activeElement || null;
    Viewport.lockTabbing(el, focusEl);
};

export const unlockTabbing = (focusEl = null) => {
    if (!isTabbingLocked) {
        return;
    }
    isTabbingLocked = false;
    const { scrollTop } = Viewport;
    Viewport.releaseTabbing(focusEl || focusedElementBeforeTabbingLocked);
    window.scrollTo(0, scrollTop);
    focusedElementBeforeTabbingLocked = null;
};

export const getRandomMinMax = (min, max) => Math.random() * (max - min) + min;

export const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

export const nearestEven = num => 2 * Math.round(num / 2);

export const isKey = (e, key) => e.key === key || e.code === key || {
    Backspace: 8,
    Escape: 27,
    Space: 32,
    Enter: 13
}[e.keyCode] === key;

export const isKeyCombo = e => e.ctrlKey || e.shiftKey || e.metaKey || (e.button && e.button === 1);

export const isModalIframe = () => (window.frameElement && window.frameElement.hasAttribute('data-modal'));

export const getModalIframeType = () => (isModalIframe() ? window.frameElement.getAttribute('data-modal') : null);

export const isLivePreview = () => document.documentElement.classList.contains('is-preview');

export const setHtml = (container, html) => {
    Components.destroy(container);
    container.innerHTML = html;
    Components.init(container);
    setTimeout(() => {
        container.scrollTop = 0;
    }, 0);
};

export const isTouch = () => !document.documentElement.classList.contains('using-mouse');

export const areRectsIntersecting = (r1, r2) => !(r2.left > r1.right || r2.right < r1.left || r2.top > r1.bottom || r2.bottom < r1.top);

export const shuffleArray = array => {
    for (let i = array.length - 1; i > 0; i -= 1) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
};

export const setSessionStorage = (key, value) => {
    if (window.sessionStorage) {
        window.sessionStorage.setItem(key, value);
    }
};

export const getSessionStorage = (key, defaultValue = null) => {
    const value = (window.sessionStorage ? window.sessionStorage.getItem(key) : null);
    if (value) {
        return value;
    }
    return defaultValue;
};

/*
    postMessage helpers to ease the communication from modal iframes to the parent window
 */
export const MESSAGE_CLOSE = 'close';
export const MESSAGE_LOAD = 'load';
export const MESSAGE_NAVIGATION = 'navigation';
export const MESSAGE_READY = 'ready';

export const messageParent = message => {
    if (!isModalIframe()) {
        return;
    }
    window.parent.postMessage(message, window.location.origin);
};

/**
 * @param timeline
 * @param clearProps
 */
export const clearTimelineProps = (timeline, clearProps = 'all') => {
    const children = timeline.getChildren();
    children.forEach(child => {
        if (typeof child.targets === 'function') {
            gsap.set(child.targets(), { clearProps });
        } else {
            clearTimelineProps(child, clearProps);
        }
    });
};
