import gsap from 'gsap';
import $ from '../../core/Dom';
import Dispatch from '../../core/Dispatch';
import superagent from '../../core/request';
import { scrollTo, setHtml } from '../../lib/helpers';
import { trackPageView } from '../../modules/Tracking';
import { AJAX_LOAD_END, AJAX_LOAD_START, DOM_CHANGED } from '../../lib/events';

export default el => {

    const $el = $(el);
    const containerSelector = el.dataset.target;
    const $container = $(containerSelector).eq(0);
    const filters = $el.find('[data-filter]').get();

    let request = null;

    const isFilterActive = filter => filter.classList.contains('bg-black');

    const isFilterEnabled = filter => filter.getAttribute('aria-disabled') !== 'true';

    const toggleFilterActive = (filter, active) => {
        if (active) {
            filter.classList.add('bg-black', 'text-white');
        } else {
            filter.classList.remove('bg-black', 'text-white');
        }
    };

    const toggleFilterEnabled = (filter, enabled) => {
        if (enabled) {
            filter.removeAttribute('aria-disabled');
        } else {
            filter.setAttribute('aria-disabled', 'true');
        }
    };

    const updateHtml = html => {
        console.log('update html');
        const $html = $('<div />').html(html);
        const $newContainer = $html.find(containerSelector).eq(0);
        // Make sure filter states match
        const $newFilters = $html.find('[data-component="default/FilteredView"]')
            .eq(0);
        $el.find('[data-filter]')
            .each(node => {
                const newNode = $newFilters.find(`[data-filter="${node.dataset.filter}"]`)
                    .get(0);
                if (!newNode) {
                    return;
                }
                node.href = newNode.href;
                toggleFilterActive(node, isFilterActive(newNode));
                toggleFilterEnabled(node, isFilterEnabled(newNode));
            });
        setHtml($container.get(0), $newContainer.html());
        gsap.set($container.get(0), { clearProps: 'opacity' });
        try {
            const title = html.match(/<title[^>]*>([^<]+)<\/title>/)[1];
            if (title) {
                document.title = title;
            }
        } catch (error) {
            console.error(error);
        }
        Dispatch.emit(DOM_CHANGED);
    };

    const setActiveFilter = filter => {
        if (isFilterActive(filter) || !isFilterEnabled(filter)) {
            return;
        }
        const group = $(filter)
            .parent('[data-filter-group]')
            .get(0) || el;
        $(group)
            .find('[data-filter].bg-black')
            .each(node => {
                toggleFilterActive(node, false);
            });
        toggleFilterActive(filter, true);
    };

    const onLinkClick = e => {

        const { triggerTarget: target } = e;
        const { href: url } = target;

        let filter;

        if (target.hasAttribute('data-filter')) {
            filter = target;
        } else {
            for (let i = 0; i < filters.length; i += 1) {
                if (filters[i].href === url) {
                    filter = filters[i];
                    break;
                }
            }
        }

        if (!filter) {
            return true;
        }

        e.preventDefault();
        e.stopImmediatePropagation();

        if (filter.hasAttribute('aria-disabled')) {
            return false;
        }

        if (request) {
            request.abort();
        }

        setActiveFilter(filter);

        window.history.pushState(null, '', url);

        scrollTo(0);

        gsap.killTweensOf($container.get(0));
        gsap.to($container.get(0), {
            opacity: 0,
            duration: 0.3
        });

        Dispatch.emit(AJAX_LOAD_START);

        request = superagent.get(url);
        request
            .set('Accept', 'text/html')
            .then(({
                status,
                text
            }) => {
                if (status !== 200 || !text) {
                    throw new Error();
                }
                const poll = setInterval(() => {
                    if (!gsap.isTweening($container.get(0))) {
                        clearInterval(poll);
                        updateHtml(text);
                        Dispatch.emit(AJAX_LOAD_END);
                    }
                }, 10);
                trackPageView(url);
            })
            .catch(error => {
                console.warn(error);
            })
            .then(() => {
                request = null;
            });

        return false;
    };

    $el.on('click', 'a', onLinkClick);

    if (ENV !== 'production') {
        Dispatch.emit(DOM_CHANGED);
    }

    return {
        destroy() {
            $el.off('click');
        }
    };

};
