import Viewport from '../core/Viewport';
import $ from '../core/Dom';
import {
    getRandomMinMax, isModalIframe,
    nearestEven,
    shuffleArray
} from '../lib/helpers';
import Dispatch from '../core/Dispatch';
import { DOM_CHANGED } from '../lib/events';

const DEBUG_COLOR = 'rgba(255, 0, 0, 0.1)';

let width;
let height;
let ctx;

let dotColor = '#B97DFF';
let debugMode = false;
let staticMode = false;
let previewMode = false;

const drawText = (text, x, y) => {
    ctx.font = '10px Arial';
    ctx.fillStyle = 'black';
    ctx.fillText(text, x, y);
};

const drawDot = (x, y, diameter, color = dotColor) => {
    ctx.beginPath();
    ctx.arc(x, y, diameter / 2, 0, 2 * Math.PI, false);
    ctx.fillStyle = color;
    ctx.fill();
};

const getDotCoordinates = (x, y, diameter, marginX = 0, marginY = 0, offsetX = 0, offsetY = 0) => ({
    x: (diameter / 2) + (diameter * x) + (marginX * x) + offsetX,
    y: (diameter / 2) + (diameter * y) + (marginY * y) + offsetY
});

const concentricPattern = (config, dots) => {

    const {
        minNumCircles,
        maxDotSize, // The maximum dot diameter
        gutter
    } = config;

    // Calculate the dot size from
    const dotSize = Math.min(Math.floor((width - (gutter * minNumCircles)) / minNumCircles), maxDotSize);

    // To find the number of dots we need, calculate the circumference for the entire screen
    const canvasRadius = (Math.max(width, height) / 2) + 150; // Throw in an extra 150px because the circle isn't always large enough
    const canvasArea = Math.PI * canvasRadius * canvasRadius;
    const dotRadius = (dotSize + gutter) / 2;
    const dotArea = Math.PI * dotRadius * dotRadius;

    const numDots = Math.ceil(canvasArea / dotArea);

    // center of the circles
    const centerX = Math.round(width / 2);
    const centerY = Math.round(height / 2);

    const render = dotsToRender => {

        // Draw center dot
        if (debugMode && dotsToRender.indexOf(0) === -1) {
            drawDot(centerX, centerY, dotSize, DEBUG_COLOR);
            drawText(0, centerX - 9, centerY + 5);
        }

        let k = 1;
        let i = 1;

        while (i < numDots) {

            // number of elements on this circle
            const steps = k * 6;

            // angular distance between elements
            const angleRange = 2 * (Math.PI / steps);

            const radius = k * (dotSize + gutter); // This is the gutter

            let j = 0;

            while (j < steps) {
                const angle = j * angleRange;
                const x = Math.round(centerX + radius * Math.cos(angle));
                const y = Math.round(centerY + radius * Math.sin(angle));
                if (dotsToRender.indexOf(i) > -1) {
                    drawDot(x, y, dotSize); // Draw the dot
                } else if (debugMode) {
                    drawDot(x, y, dotSize, DEBUG_COLOR); // Draw the dot anyway, if debugging
                }
                if (debugMode) {
                    drawText(i, x - 9, y + 5);
                }
                i += 1;
                j += 1;
            }

            k += 1;
        }

    };

    return {
        numDots,
        dots,
        render
    };

};

const gridPattern = (config, dots) => {

    const {
        minDotsX,
        maxDotSize,
        gutterX,
        gutterY
    } = config;

    const gridWidth = nearestEven(Math.max(width, height));
    const dotSize = nearestEven(Math.min(Math.round((width - (gutterX * (minDotsX - 1))) / minDotsX), maxDotSize));
    const numDotsX = nearestEven(Math.ceil(gridWidth / (dotSize + gutterX)));
    const numDotsY = numDotsX;

    const numDots = numDotsX * numDotsY;

    const offsetX = Math.round((width - (((numDotsX + 1) * dotSize) + ((numDotsX - 1) * gutterX))) / 2);
    const offsetY = Math.round((height - ((numDotsY * dotSize) + ((numDotsY - 1) * gutterY))) / 2);

    const render = dotsToRender => {

        // Figure out the starting dot's position
        let x = Math.round(numDotsX / 2);
        let y = Math.round(numDotsY / 2);

        // Render the center dot?
        if (dotsToRender.indexOf(0) > -1 || debugMode) {
            const {
                x: dotX,
                y: dotY
            } = getDotCoordinates(x, y, dotSize, gutterX, gutterY, offsetX, offsetY);
            drawDot(dotX, dotY, dotSize, debugMode ? DEBUG_COLOR : dotColor);
            if (debugMode) {
                ctx.font = '10px Arial';
                ctx.fillStyle = 'black';
                ctx.fillText('0', dotX - 9, dotY + 5);
            }
        }

        // Render all the dots
        let distance = 0;
        let range = 1;
        let direction = 'up';

        for (let i = 1; i < numDots; i += 1) {
            distance += 1;
            switch (direction) {
                case 'up':
                    y += 1;
                    if (distance >= range) {
                        direction = 'right';
                        distance = 0;
                    }
                    break;
                case 'right':
                    x += 1;
                    if (distance >= range) {
                        direction = 'bottom';
                        distance = 0;
                        range += 1;
                    }
                    break;
                case 'bottom':
                    y -= 1;
                    if (distance >= range) {
                        direction = 'left';
                        distance = 0;
                    }
                    break;
                case 'left':
                    x -= 1;
                    if (distance >= range) {
                        direction = 'up';
                        distance = 0;
                        range += 1;
                    }
                    break;
                default:
                    break;
            }
            const {
                x: dotX,
                y: dotY
            } = getDotCoordinates(x, y, dotSize, gutterX, gutterY, offsetX, offsetY);
            if (dotsToRender.indexOf(i) > -1) {
                drawDot(dotX, dotY, dotSize);
            } else if (debugMode) {
                drawDot(dotX, dotY, dotSize, DEBUG_COLOR);
            }
            if (debugMode) {
                ctx.font = '10px Arial';
                ctx.fillStyle = 'black';
                ctx.fillText(`${i}`, dotX - 9, dotY + 5);
            }
        }

    };

    return {
        dots,
        numDots,
        render
    };

};

export default canvas => {

    debugMode = !!canvas.dataset.debug;
    staticMode = !!canvas.dataset.static;
    previewMode = !!canvas.dataset.preview;

    ({ color: dotColor = dotColor } = canvas.dataset);

    let pattern;
    let ticker;
    let timer;
    let visible = document.visibilityState !== 'hidden';

    const timeout = parseInt(canvas.dataset.timeout, 10) * 1000;

    const patterns = {
        logo: () => gridPattern({
            minDotsX: 44, // The minimum number of dots in a single row
            maxDotSize: 38, // The maximum dot diameter
            gutterX: 0,
            gutterY: Math.min(Math.round(width * (7 / 1440)), 7) // 7px at 1440px wide
        }, [
            1068, 941, 822, 711, 608, 347, 276, 213, 158, 67, 193, 252, 319, 394, 477, 889, 889, 1012, 1143, 1282, 1429,
            1204, 348, 112, 39, 37, 251, 567, 888,
            1348, 349, 113, 19, 16, 250, 566, 887,
            1349, 944, 825, 714, 611, 429, 350, 279, 216, 161, 21, 6, 5, 4, 15, 34, 190, 249, 316, 391, 474, 771, 886, 1009, 1140, 1279,
            1350, 826, 430, 217, 22, 33, 189, 770,
            1351, 946, 431, 163, 46, 32, 137, 769,
            1209, 1074, 519, 117, 47, 12, 136, 661, 768, 883, 1006, 1137
        ]),
        smiley: () => concentricPattern({
            minNumCircles: 11,
            maxDotSize: 38, // The maximum dot diameter
            gutter: Math.min(Math.round(width * (15 / 1440)), 15) // 15px at 1440px wide
        }, [
            14, 18, // Eyes
            12, 11, 10, 9, 8, // Mouth
            37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60 // Outline
        ]),
        '404': () => gridPattern({
            minDotsX: 50, // The minimum number of dots in a single row
            maxDotSize: 38, // The maximum dot diameter
            gutterX: 0,
            gutterY: Math.min(Math.round(width * (7 / 1440)), 7) // 7px at 1440px wide
        }, [
            427, 428, 346, 347, 273, 274, 271, 209, 156, 157, 158, 159, 113, 72, 73, 153, 108, 266, 205, // N
            106, 69, 150, 39, 202, 17, 262, 16, 261, 35, 260, 63, 198, 99, 143, 144, // 0
            257, 256, 251, 250, 323, 320, 319, 396, 397, 398, 481, 572, 671, // T
            1502, 1505, 1507, 1508, 1351, 1354, 1355, 1208, 1209, 1210, 1211, 1073, 1076, 946, 949, 827, 830, 716, // F
            616, 617, 520, 523, 432, 437, 352, 358, 281, 287, 218, 223, 164, 167, 118, 119, // 0
            48, 49, 82, 123, 23, 24, 173, 174, 175, 127, 54, 87, 32, 31, 30, // U
            130, 179, 91, 90, 136, 135, 188, 246, 245, 244, 243, 242, 308, 385, 384, 470, 469, 563, 562, // N
            656, 769, 765, 764, 763, 884, 883, 882, 881, 878, 1007, 1001, 1138, 1133, 1276, 1273, 1422, 1421
        ]),
    };

    const animate = () => {

        const {
            dots: patternDots,
            numDots
        } = pattern;

        if (!numDots || !patternDots.length) {
            return;
        }

        const dotsToRender = [];

        if (ticker) {
            clearInterval(ticker);
            ticker = null;
        }

        if (timer) {
            clearTimeout(timer);
            timer = null;
        }

        if (staticMode) {
            pattern.render(patternDots);
            return;
        }

        // We want to render 15% of the total dots randomly, or double the amount of pattern dots - whichever is more!
        const numDotsToRender = Math.max(Math.round(numDots * 0.1), patternDots.length * 1.5);

        const getPatternDots = () => shuffleArray(patternDots.slice(0));

        const getRandomDots = () => shuffleArray([...Array(numDots)
            .keys()])
            .filter(dot => patternDots.indexOf(dot) === -1)
            .slice(0, numDotsToRender);

        const removeRandomDot = () => {
            for (let i = 0; i < dotsToRender.length; i += 1) {
                if (patternDots.indexOf(dotsToRender[i]) === -1) {
                    dotsToRender.splice(i, 1);
                    break;
                }
            }
        };

        const interval = 350;

        let dotsToAdd = [];
        let step = 1;

        ticker = setInterval(() => {

            if (step === 1) {

                if (!dotsToAdd.length) {
                    dotsToAdd = shuffleArray(getRandomDots()
                        .concat(getPatternDots()));
                }

                for (let i = 0; i < Math.round(getRandomMinMax(3, 6)); i += 1) {
                    dotsToRender.splice(dotsToRender.length, 0, dotsToAdd.shift());
                    if (!dotsToAdd.length) {
                        step += 1;
                        break;
                    }
                }

            } else if (step === 2) {

                for (let i = 0; i < Math.round(getRandomMinMax(2, 4)); i += 1) {

                    removeRandomDot();

                    if (dotsToRender.length === patternDots.length) {
                        timer = setTimeout(() => {
                            step = 3;
                        }, 2000);
                    }
                }

            } else if (step === 3) {

                if (!dotsToAdd.length) {
                    dotsToAdd = getRandomDots();
                }

                for (let j = 0; j < dotsToRender.length; j += 1) {
                    if (patternDots.indexOf(dotsToRender[j]) > -1) {
                        dotsToRender.splice(j, 1);
                        break;
                    }
                }

                for (let i = 0; i < Math.round(getRandomMinMax(2, 4)); i += 1) {
                    dotsToRender.splice(dotsToRender.length, 0, dotsToAdd.shift());
                }

                if (dotsToRender.length >= numDotsToRender) {
                    step += 1;
                }

            } else if (step === 4) {

                for (let i = 0; i < Math.round(getRandomMinMax(2, 4)); i += 1) {
                    dotsToRender.shift();
                }

                if (!dotsToRender.length) {
                    step += 1;
                }

            } else {

                clearInterval(ticker);
                ticker = null;

                // Restart the pattern
                animate();

                return;

            }

            ctx.clearRect(0, 0, width, height);
            pattern.render(dotsToRender);

        }, interval);

    };

    const createPattern = () => {
        const { pattern: patternKey } = canvas.dataset;
        if (patternKey) {
            pattern = patterns[patternKey];
        } else {
            const patternKeys = ['logo', 'smiley'];
            pattern = patterns[patternKeys[Math.floor(Math.random() * patternKeys.length)]];
        }
        pattern = pattern();
    };

    const initCanvas = () => {

        ({
            width,
            height
        } = canvas.getBoundingClientRect());

        const pixelRatio = Math.max(window.devicePixelRatio || 1, 2);

        canvas.width = width * pixelRatio;
        canvas.height = height * pixelRatio;

        ctx = canvas.getContext('2d');
        ctx.scale(pixelRatio, pixelRatio);
        ctx.clearRect(0, 0, width, height);

    };

    let isVisible = false;

    const resizeHandler = () => requestAnimationFrame(() => {
        if (!isVisible) {
            return;
        }
        initCanvas();
        createPattern();
        animate();
    });

    const hide = () => {
        if (!isVisible) {
            return;
        }
        isVisible = false;
        if (ticker) {
            clearInterval(ticker);
            ticker = null;
        }
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }
        canvas.hidden = true;
        ctx.clearRect(0, 0, width, height);
    };

    const show = () => {
        if (isVisible || !!$('object[data-modal]').get(0)) {
            return;
        }
        isVisible = true;
        canvas.hidden = false;
        resizeHandler();
    };

    const clearTimer = () => {
        if (!timer) {
            return;
        }
        clearTimeout(timer);
        timer = null;
    };

    const createTimer = () => {
        clearTimer();
        if (!timeout) {
            return;
        }
        timer = setTimeout(() => {
            timer = null;
            show();
        }, timeout);
    };

    const events = ['mousemove', 'touchmove', 'touchstart', 'click', 'scroll'];

    const onEvent = () => {
        hide();
        createTimer();
    };

    const onVisibilityChange = () => {
        if (staticMode) {
            return;
        }
        visible = document.visibilityState !== 'hidden';
        if (visible) {
            createTimer();
        } else {
            clearTimer();
        }
    };

    const init = () => {
        Viewport.on('resize', resizeHandler);
        if (previewMode) {
            show();
            return;
        }
        createTimer();
        events.forEach(event => {
            window.addEventListener(event, onEvent);
        });
        document.addEventListener('visibilitychange', onVisibilityChange);
        console.log('screensaver init');
    };

    const destroy = () => {
        Viewport.off('resize', resizeHandler);
        hide();
        events.forEach(event => {
            window.removeEventListener(event, onEvent);
        });
        document.removeEventListener('visibilitychange', onVisibilityChange);
        clearTimer();
    };

    if (ENV !== 'production') {
        Dispatch.emit(DOM_CHANGED);
    }

    return {
        init,
        destroy
    };

};
