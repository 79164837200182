import gsap from 'gsap';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { UNLOAD } from '../lib/events';
import { isKeyCombo, isModalIframe, MESSAGE_NAVIGATION, messageParent } from '../lib/helpers';

const clickHandler = e => {

    const { triggerTarget: target } = e;
    const href = target.getAttribute('href');
    const host = window.location.host.split(':')[0];

    // Check if the click was eligble for transition
    if (!href || href[0] === '#' || target.getAttribute('target') === '_blank' || isKeyCombo(e) || target.hasAttribute('download') || href.indexOf(host) === -1 || href.indexOf('actions/debug') > -1 || (isModalIframe() && target.hasAttribute('data-modalclosebtn'))) {
        return;
    }

    e.preventDefault();

    messageParent(MESSAGE_NAVIGATION);

    Dispatch.emit(UNLOAD);

    gsap.timeline()
        // Tween the document background to white
        .set(document.documentElement, {
            backgroundColor: 'white'
        }, 0)
        .to(document.body, {
            backgroundColor: 'white',
            duration: 0.29,
            ease: 'Quad.easeIn'
        }, 0)
        // Tween out the body opacity
        .to(document.body, {
            opacity: 0,
            duration: 0.29,
            ease: 'Quad.easeIn'
        }, 0);

    // Redirect
    setTimeout(() => {
        window.location.href = href;
    }, 300);
};

const initLinks = () => {
    $('body')
        .on('click', 'a', clickHandler);
};

const init = () => {

    initLinks();

    // Fix for Safari's back button (back-forward cache)
    window.onpageshow = e => {
        if (e.persisted) {
            initLinks();
            setTimeout(() => {
                gsap.set([document.documentElement, document.body], { clearProps: 'opacity' });
            }, 100);
        }
    };
};

export default ({
    init
});
