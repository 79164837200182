import gsap from 'gsap';
import $ from '../../core/Dom';
import Viewport from '../../core/Viewport';

import { isTouch } from '../../lib/helpers';

export default el => {

    const $el = $(el);
    const container = $el.parent().get(0);

    const offsetX = 0;
    const offsetY = 5;

    let isVisible = false;

    const hide = () => {
        if (!isVisible) {
            return;
        }
        isVisible = false;
        gsap.set(container, { clearProps: 'cursor' });
        gsap.set(el, { clearProps: 'visibility' });
    };

    const show = () => {
        if (isVisible || isTouch()) {
            return;
        }
        isVisible = true;
        gsap.set(container, { cursor: 'none' });
        gsap.set(el, { visibility: 'visible' });
    };

    const xSet = gsap.quickSetter(el, 'x', 'px');
    const ySet = gsap.quickSetter(el, 'y', 'px');

    let prevX = 0;
    let prevY = 0;

    const setCursorPos = (x, y) => {
        const { left, top } = container.getBoundingClientRect();
        xSet(x - left - offsetX);
        ySet(y - top - offsetY);
        prevX = x;
        prevY = y;
    };

    const trackCursor = e => {
        show();
        setCursorPos(e.clientX, e.clientY);
    };

    $(container)
        .off('mouseenter', show)
        .on('mousemove', trackCursor)
        .on('mouseleave', hide);

    const onScroll = () => {
        if (!isVisible || isTouch()) {
            return;
        }
        const { top, height } = container.getBoundingClientRect();
        if (prevY > (height + top) || prevY < top) {
            hide();
            return;
        }
        setCursorPos(prevX, prevY);
    };

    Viewport.on('scroll', onScroll);

    return {
        destroy() {
            hide();
            $(container)
                .off('mouseenter', show)
                .off('mousemove', trackCursor)
                .off('mouseleave', hide);
            Viewport.off('scroll', onScroll);
        }
    };

};
