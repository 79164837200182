import gsap from 'gsap';
import $ from '../../core/Dom';
import Viewport from '../../core/Viewport';

export default el => {

    const $el = $(el);
    const img = $el.find('img')
        .get(0);

    const playIntro = () => {
        const { height: viewportH } = Viewport;
        const { top } = el.getBoundingClientRect();
        if (top < 0) {
            gsap.to(el, {
                opacity: 1,
                duration: 0.3
            });
        } else {
            img.classList.remove('lazyload');
            const tl = gsap.timeline({ paused: true })
                .to(el, {
                    opacity: 1,
                    duration: 0.3
                }, 'in')
                .fromTo(el.firstElementChild, { y: viewportH - top }, {
                    y: 0,
                    duration: 2,
                    ease: 'Expo.easeOut'
                }, 'in')
                .fromTo(el.firstElementChild, { scale: 0.91 }, {
                    scale: 1,
                    duration: 1.5,
                    transition: 'Cubic.easeInOut'
                }, 'in+=20%')
                .set([el, el.firstElementChild], { clearProps: 'transform' });
            gsap.timeline()
                .to(tl, {
                    progress: 1,
                    duration: 2.5,
                    ease: 'Quad.easeInOut'
                });
        }
    };

    const then = new Date().getTime();

    const pollForImageLoaded = () => {
        const imgLoaded = img.complete && !!img.naturalHeight;
        if (imgLoaded || (new Date()).getTime() - then >= 2000) {
            playIntro();
            return;
        }
        setTimeout(pollForImageLoaded, 10);
    };

    pollForImageLoaded();

};
