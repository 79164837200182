import gsap from 'gsap';
import $ from './core/Dom';
import Viewport from './core/Viewport';
import Components from './core/Components';
import Dispatch from './core/Dispatch';
import ModalFrames from './modules/ModalFrames';
import Revealer from './modules/Revealer';
import Transition from './modules/Transition';
import LightNDarks from './modules/LightNDarks';

import {
    isLivePreview,
    isModalIframe, MESSAGE_READY, messageParent,
    scrollTo
} from './lib/helpers';

import { AJAX_LOAD_END, AJAX_LOAD_START, DOM_CHANGED, FONTS_LOADED } from './lib/events';

gsap.defaults({ overwrite: 'auto' });

const init = () => {

    Viewport.init();
    Components.init();
    Viewport.initTabbing();

    LightNDarks();

    const $body = $('body');

    // Anchor links
    $body.on('click', 'a[href^="#"]:not([role="button"])', e => {
        if (e.triggerTarget.hasAttribute('data-noanchor')) {
            e.stopImmediatePropagation();
            return true;
        }
        const targetId = $(e.triggerTarget).attr('href').slice(1);
        const target = targetId ? $(`#${targetId}`).get(0) : null;
        if (!target) {
            return true;
        }
        e.preventDefault();
        e.stopImmediatePropagation();
        scrollTo(target);
        return false;
    });

    // Modal iframe stuff
    ModalFrames.init();

    // Page transitions
    if (!isLivePreview()) {
        Transition.init();
    }

    // Background color transitions
    Dispatch.on(FONTS_LOADED, () => {
        if ($body.hasClass('js:bg-white')) {
            $body.removeClass('js:bg-white');
            const { backgroundColor } = window.getComputedStyle(document.body);
            gsap.timeline()
                .fromTo(document.body, { backgroundColor: 'white' }, {
                    backgroundColor,
                    duration: 0.5,
                    ease: 'Quad.easeInOut'
                })
                .set(document.body, { clearProps: 'backgroundColor' });
        }
        if (!isLivePreview()) {
            Revealer.init();
            Dispatch.on(DOM_CHANGED, Revealer.update);
        }
    }, true);

    // Check if page is ready
    let fontsLoaded = false;
    let heroImageLoaded = false;
    let heroImageTimeout = null;
    let pageIsReady = false;

    const maybePageIsReady = () => {
        if (pageIsReady) {
            return;
        }
        pageIsReady = fontsLoaded && heroImageLoaded;
        if (!pageIsReady) {
            return;
        }
        if (heroImageTimeout) {
            clearTimeout(heroImageTimeout);
        }
        if (isModalIframe()) {
            messageParent(MESSAGE_READY);
        } else {
            Dispatch.emit(AJAX_LOAD_END);
        }
    };

    setTimeout(() => {

        // Loader
        if (!isModalIframe()) {
            Dispatch.emit(AJAX_LOAD_START);
        }

        const heroImage = $('[data-heroimage] img')
            .get(0);

        if (heroImage && heroImage.classList.contains('lazyload')) {
            heroImage.addEventListener('lazyloaded', () => {
                heroImageLoaded = true;
                maybePageIsReady();
            });
            // Just in case it never loads
            heroImageTimeout = setTimeout(() => {
                clearTimeout(heroImageTimeout);
                heroImageTimeout = null;
                heroImageLoaded = true;
                maybePageIsReady();
            }, 1000);
        } else {
            heroImageLoaded = true;
            maybePageIsReady();
        }

        Dispatch.on(FONTS_LOADED, () => {
            fontsLoaded = true;
            maybePageIsReady();
        }, true);

    }, isModalIframe() ? 0 : 300);

    const then = new Date().getTime();

    const pollForFonts = () => {
        console.log('poll for fonts...');
        if (document.documentElement.classList.contains('wf-active') || document.documentElement.classList.contains('wf-inactive') || (new Date().getTime()) - then > 3000) {
            fontsLoaded = true;
            if (!document.documentElement.classList.contains('wf-active') && !document.documentElement.classList.contains('wf-inactive')) {
                console.warn('Font loader failed');
                document.documentElement.classList.add('wf-inactive');
            }
            maybePageIsReady();
            Dispatch.emit(FONTS_LOADED);
            console.log('Fonts loaded (probably)');
            return;
        }
        setTimeout(pollForFonts, 100);
    };

    pollForFonts();

};

require('doc-ready')(init);
