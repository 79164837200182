import $ from '../../core/Dom';
import Viewport from '../../core/Viewport';

export default el => {

    const $el = $(el);
    const $main = $('#main');

    let { height: viewportH } = Viewport;

    let isSticky = $el.hasClass('sticky');

    // Make the footer sticky if it's not taller than the viewport, but <main> is
    const maybeMakeSticky = () => {
        const maxHeight = viewportH - 150;
        const makeSticky = $main.height() > maxHeight && $el.height() < maxHeight;
        if (makeSticky === isSticky) {
            return;
        }
        if (makeSticky) {
            $el.addClass('sticky');
        } else {
            $el.removeClass('sticky');
        }
        isSticky = makeSticky;
    };

    const onResize = () => {
        viewportH = Viewport.height;
        maybeMakeSticky();
    };

    const onInnerFocus = () => {
        const { scrollTop } = Viewport;
        const offsetTop = $el.offset().top;
        if (scrollTop < offsetTop) {
            Viewport.scrollTo(el, { duration: 0 });
        }
    };

    const init = () => {
        Viewport.on('resize', onResize);
        $el.on('focusin', 'a,button', onInnerFocus);
        onResize();
    };

    const destroy = () => {
        Viewport.off('resize', onResize);
        $el.off('focusin');
    };

    return {
        init,
        destroy
    };

};
