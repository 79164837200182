import gsap from 'gsap';
import $ from '../core/Dom';

export default el => {

    const $el = $(el);
    const $embed = $el.find('[data-embed]');

    let observer;

    const showEmbed = () => {
        const embed = $embed.data('embed');
        $embed.html(embed);
        $embed.get(0).hidden = false;
        gsap.timeline()
            .fromTo($embed.get(0), { opacity: 0 }, { opacity: 1, duration: 0.3 }, 0);
    };

    const init = () => {
        observer = new IntersectionObserver(([{ isIntersecting }]) => {
            if (!isIntersecting) {
                return;
            }
            observer.disconnect();
            observer = null;
            showEmbed();
        }, {
            rootMargin: '200%'
        });
        observer.observe(el);
    };

    const destroy = () => {
        if (observer) {
            observer.disconnect();
            observer = null;
        }
    };

    return {
        init,
        destroy
    };

};
