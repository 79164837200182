import gsap from 'gsap';
import $ from '../../core/Dom';
import { getScrollbarWidth, isScrollLocked, setHtml } from '../../lib/helpers';
import Dispatch from '../../core/Dispatch';
import superagent from '../../core/request';
import { AJAX_LOAD_END, AJAX_LOAD_START, DOM_CHANGED, SCROLL_LOCKED, SCROLL_UNLOCKED } from '../../lib/events';

import { trackPageView } from '../../modules/Tracking';
import Viewport from '../../core/Viewport';

export default el => {

    const $el = $(el);
    const wrapper = $el.find('[data-wrapper]').get(0);
    const content = $el.find('[data-content]').get(0);

    let request;

    const updateHtml = html => {
        let bodyBgColorClass = '';
        let newBodyBgColorClass = '';
        try {
            bodyBgColorClass = Array.from(document.body.classList).reduce((carry, className) => carry || (className.startsWith('bg-') ? className : null), null);
            newBodyBgColorClass = html.match(/body id="top"\sclass=['|"]([^'|"]*)['|"]/)[1].match(/(bg\-\w+)/g).pop();
        } catch (error) {
            console.error(error);
        }
        if (bodyBgColorClass && newBodyBgColorClass && newBodyBgColorClass !== bodyBgColorClass) {
            const { backgroundColor } = window.getComputedStyle(document.body);
            document.body.classList.remove(bodyBgColorClass);
            document.body.classList.add(newBodyBgColorClass);
            const { backgroundColor: newBackgroundColor } = window.getComputedStyle(document.body);
            gsap.timeline()
                .fromTo(document.body, { backgroundColor }, { backgroundColor: newBackgroundColor, duration: 0.5, ease: 'Quad.easeInOut' })
                .set(document.body, { clearProps: 'backgroundColor' });
        }
        const $html = $(`<div>${html}</div>`);
        const $newContent = $html.find('[data-content]').eq(0);
        const tl = gsap.timeline();
        const opacity = gsap.getProperty(content, 'opacity');
        if (opacity) {
            tl.to(content, { opacity: 0, duration: 0.3 });
        }
        tl
            .add(() => {
                setHtml(content, $newContent.html());
                $(content).parent().get(0).scrollTop = 0;
            })
            .set(content, { clearProps: 'pointerEvents' })
            .to(content, { opacity: 1, duration: 0.5 });
        try {
            const title = html.match(/<title[^>]*>([^<]+)<\/title>/)[1];
            if (title) {
                document.title = title;
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onScrollLocked = () => {
        if (!getScrollbarWidth()) {
            return;
        }
        if (window.getComputedStyle(wrapper).position === 'fixed') {
            wrapper.style.paddingRight = `${getScrollbarWidth()}px`;
        } else {
            wrapper.style.paddingRight = '';
        }
    };

    const onScrollUnlocked = () => {
        wrapper.style.paddingRight = '';
    };

    const onLinkClick = e => {

        e.preventDefault();
        e.stopImmediatePropagation();

        if (request) {
            gsap.set(content, { clearProps: 'pointerEvents' });
            request.abort();
        }

        const { href: url } = e.triggerTarget;

        request = superagent.get(url);

        Dispatch.emit(AJAX_LOAD_START);

        gsap.timeline()
            .set(content, { pointerEvents: 'none' })
            .to(content, { opacity: 0, duration: 0.3 });

        request
            .set('Accept', 'text/html')
            .then(({
                status,
                text: html
            }) => {
                if (status !== 200 || !html) {
                    throw new Error();
                }
                trackPageView(url);
                window.history.pushState(null, '', url);
                updateHtml(html);
            })
            .catch(error => {
                console.error(error);
            })
            .then(() => {
                request = null;
                Dispatch.emit(AJAX_LOAD_END);
            });

    };

    const onBreakpoint = () => {
        if (isScrollLocked()) {
            onScrollLocked();
        }
    };

    // Ajaxify
    $(content).on('click', 'a[data-frokostlablink],a[data-backbtn]', onLinkClick);

    Dispatch.on(SCROLL_LOCKED, onScrollLocked);
    Dispatch.on(SCROLL_UNLOCKED, onScrollUnlocked);

    Viewport.on('breakpoint', onBreakpoint);

    if (ENV !== 'production') {
        Dispatch.emit(DOM_CHANGED);
    }

    return {
        destroy() {
            $(content).off('click');
            Dispatch.off(SCROLL_LOCKED, onScrollLocked);
            Dispatch.off(SCROLL_UNLOCKED, onScrollUnlocked);
            Viewport.off('breakpoint', onBreakpoint);
        }
    };

};
