import gsap from 'gsap';
import $ from '../../core/Dom';
import Dispatch from '../../core/Dispatch';
import Viewport from '../../core/Viewport';

import { DOM_CHANGED, FONTS_LOADED, LOGO_APPEAR } from '../../lib/events';

export default el => {

    const taglines = $(el).find('[data-taglines]').get(0);

    let taglinesTl;
    let taglinesTlComplete = false;
    let taglinesScrollTl;

    const maybeScrollTaglines = () => {
        if (!taglines || !taglinesTlComplete) {
            return;
        }
        if (taglinesScrollTl) {
            taglinesScrollTl.kill();
            gsap.set(taglines.parentNode, { clearProps: 'all' });
        }
        const { width: taglinesWidth } = taglines.parentNode.getBoundingClientRect();
        const { width: taglinesInnerWidth } = taglines.getBoundingClientRect();
        let extraPixels = Math.ceil(taglinesInnerWidth - taglinesWidth);
        if (extraPixels <= 0) {
            return;
        }
        extraPixels += 2;
        const pixelsPerSecond = 80;
        let duration = extraPixels / pixelsPerSecond;
        if (duration < 1.3) {
            duration = 1.3;
        } else if (duration > 2.5) {
            duration = 2.5;
        }
        taglinesScrollTl = gsap.timeline({
            repeat: -1,
            yoyo: true,
            repeatDelay: 1.5
        })
            .set(taglines.parentNode, { maxWidth: 'none' })
            .fromTo(taglines.parentNode, { width: taglinesWidth, x: 0 }, { width: taglinesInnerWidth + 2, x: -extraPixels, duration, ease: 'Cubic.easeInOut' }, 'anim+=1');
    };

    const animateTaglines = () => {
        if (!taglines) {
            return;
        }
        const taglineNodes = Array.from(taglines.children);
        taglinesTl = gsap.timeline({
            onComplete() {
                taglinesTlComplete = true;
                maybeScrollTaglines();
            }
        });
        const persistLast = taglineNodes.length === 1;
        taglineNodes.forEach((taglineNode, i) => {
            const isFirst = !i;
            const isLast = i >= (taglineNodes.length - 1);
            const tl = gsap.timeline()
                .add(() => {
                    taglineNode.hidden = false;
                    taglineNodes.forEach(node => {
                        if (node === taglineNode) {
                            return;
                        }
                        node.hidden = true;
                    });
                    Dispatch.emit(DOM_CHANGED);
                })
                .fromTo(taglineNode.children, { opacity: 0, position: 'absolute' }, {
                    opacity: 1,
                    position: 'static',
                    duration: 0.001,
                    stagger: 0.15
                }, isFirst ? 'in' : 'in+=0.5');
            if (!isLast || !persistLast) {
                tl
                    .add(() => {
                    }, 'in+=3')
                    .to(taglineNode.children, {
                        delay: 0.3,
                        opacity: 0,
                        position: 'absolute',
                        duration: 0.001,
                        stagger: {
                            each: 0.05,
                            from: 'end'
                        }
                    }, 'out')
                    .add(() => {
                    }, 'out+=0.5');
            }
            taglinesTl.add(tl);
        });
    };

    let hasAnimated = false;

    const onHeaderAppear = () => {
        if (hasAnimated) {
            return;
        }
        hasAnimated = true;
        animateTaglines();
    };

    let hasInited = false;
    let { width: viewportWidth } = Viewport;

    const onResize = () => {
        if (!hasInited || Viewport.width === viewportWidth) {
            return;
        }
        viewportWidth = Viewport.width;
        maybeScrollTaglines();
    };

    const init = () => {
        if (hasInited) {
            return;
        }
        hasInited = true;
        Dispatch.on(LOGO_APPEAR, onHeaderAppear, true);
    };

    Dispatch.on(FONTS_LOADED, init, true);

    Viewport.on('resize', onResize);

    if (ENV !== 'production') {
        Dispatch.emit(DOM_CHANGED);
    }

    return {
        init,
        destroy() {
            if (taglinesTl) {
                taglinesTl.kill();
            }
            if (taglinesScrollTl) {
                taglinesScrollTl.kill();
            }
            Dispatch.off(FONTS_LOADED, init);
            Dispatch.off(LOGO_APPEAR, onHeaderAppear);
            Viewport.off('resize', onResize);
        }
    };

};
