import gsap from 'gsap';
import Dispatch from '../core/Dispatch';
import { AJAX_LOAD_END, AJAX_LOAD_START } from '../lib/events';

export default el => {

    const progressTl = gsap.timeline({
        paused: true
    })
        .fromTo(el, { opacity: 0 }, {
            opacity: 1,
            duration: 0.3
        }, 0)
        .fromTo(el, { scaleX: 0 }, {
            scaleX: 1,
            duration: 1,
            transformOrigin: 'left center',
            ease: 'Cubic.easeInOut'
        }, 0);

    let endTl;

    const onAjaxLoadStart = () => {
        if (endTl) {
            endTl.kill();
            endTl = null;
        }
        if (progressTl.time()) {
            gsap.to(progressTl, {
                progress: 0,
                duration: 0.15,
                ease: 'none',
                onComplete() {
                    progressTl.invalidate();
                    progressTl.play(0);
                }
            });
        } else {
            progressTl.invalidate();
            progressTl.play();
        }
    };

    let loadEndTimeout;

    const onAjaxLoadEnd = () => {
        if (endTl) {
            endTl.kill();
        }
        gsap.killTweensOf(progressTl);
        if (loadEndTimeout) {
            clearTimeout(loadEndTimeout);
        }
        loadEndTimeout = setTimeout(() => {
            clearTimeout(loadEndTimeout);
            loadEndTimeout = null;
            endTl = gsap.timeline({
                onComplete() {
                    endTl = null;
                    gsap.set(el, { clearProps: 'opacity' });
                    progressTl.invalidate();
                    progressTl.pause(0);
                }
            });
            if (progressTl.time()) {
                endTl.to(progressTl, {
                    progress: 1,
                    duration: progressTl.progress() <= 0.9 ? 0.3 : 0.1,
                    ease: 'none'
                }, 0);
            }
            endTl.to(el, {
                opacity: 0,
                duration: 0.3
            }, '+=0.1');
        }, 60);
    };

    Dispatch.on(AJAX_LOAD_START, onAjaxLoadStart, true);
    Dispatch.on(AJAX_LOAD_END, onAjaxLoadEnd, true);

    return {
        destroy() {
            Dispatch.off(AJAX_LOAD_START, onAjaxLoadStart);
            Dispatch.off(AJAX_LOAD_END, onAjaxLoadEnd);
        }
    };

};
