/*
*   ~ Project event keys go in here ~
*
*   Usage in components:
*
*   import Events from '@vaersaagod/tools/Events';
*   import * as eventKeys from '../lib/events';
*   Events.publish(eventKeys.SOME_EVENT, 'This ES6 shit is wack');
*
 */

export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_CLOSE = 'MODAL_CLOSE';
export const MENU_OPEN = 'MENU_OPEN';
export const MENU_CLOSE = 'MENU_CLOSE';
export const SCROLL_LOCKED = 'SCROLL_LOCKED';
export const SCROLL_UNLOCKED = 'SCROLL_UNLOCKED';
export const LOGO_APPEAR = 'LOGO_APPEAR';
export const HEADER_HIDDEN = 'HEADER_HIDDEN';
export const HEADER_VISIBLE = 'HEADER_VISIBLE';
export const DOM_CHANGED = 'DOM_CHANGED';
export const FONTS_LOADED = 'FONTS_LOADED';
export const AJAX_LOAD_START = 'AJAX_LOAD_START';
export const AJAX_LOAD_END = 'AJAX_LOAD_END';
export const UNLOAD = 'UNLOAD';
