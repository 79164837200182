import { loadPillsScene } from '../../lib/async-bundles';
import Dispatch from '../../core/Dispatch';
import { FONTS_LOADED } from '../../lib/events';

export default el => {

    let pills;

    loadPillsScene(({ default: Pills }) => {
        Dispatch.on(FONTS_LOADED, function () {
            pills = Pills(el);
            Dispatch.off(FONTS_LOADED, this);
        }, true);
    });

    return {
        destroy() {
            if (pills) {
                pills.destroy();
            }
        }
    };

};
