import gsap from 'gsap';
import shuffle from 'lodash/shuffle';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Viewport from '../core/Viewport';
import { getScrollbarWidth, isKey, lockScroll, lockTabbing, unlockScroll, unlockTabbing } from '../lib/helpers';
import { MENU_CLOSE, MENU_OPEN, SCROLL_LOCKED, SCROLL_UNLOCKED } from '../lib/events';

export default el => {

    const $el = $(el);
    const $toggle = $el.find('[data-toggle]').eq(0);
    const $menu = $($toggle.get(0).nextElementSibling).eq(0);
    const $panels = $el.find('[data-panel]');
    const menuWrapper = $el.find('[data-menu-wrapper]').get(0);
    const backdrop = $el.find('[data-menu-backdrop]').get(0);
    const panelWrapper = $panels.parent().get(0);

    let isOpen = false;
    let prevPanelIndex = -1;
    let panelIndexes = [];

    let tl = null;

    const isLarge = () => ['l', 'lp', 'xl'].indexOf(Viewport.breakpoint.name) > -1;

    const maybePreloadPanelImage = () => {
        if (!isLarge()) {
            return;
        }
        const $panel = $el.find('[data-panel]:not([hidden])');
        if (!$panel.length) {
            return;
        }
        $panel.find('img.lazyload:not(.lazyloading)')
            .addClass('lazypreload');
    };

    const showNextPanel = () => {
        const numPanels = $panels.length;
        if (numPanels < 2) {
            return;
        }
        if (!panelIndexes.length) {
            panelIndexes = [...Array(numPanels)
                .keys()];
        }
        let nextPanelIndex = prevPanelIndex;
        while (nextPanelIndex === prevPanelIndex) {
            ({ 0: nextPanelIndex } = shuffle(panelIndexes));
        }
        panelIndexes.splice(panelIndexes.indexOf(nextPanelIndex), 1);
        prevPanelIndex = nextPanelIndex;
        $panels.each((panel, index) => {
            panel.hidden = index !== nextPanelIndex;
        });
    };

    showNextPanel();

    const destroyOpenCloseTl = () => {
        if (!tl) {
            return;
        }
        tl.kill();
        tl = null;
    };

    const createTl = () => {
        let progress = 0;
        if (tl) {
            progress = tl.progress();
            destroyOpenCloseTl();
        }
        const menuInner = $el.find('[data-menu-inner]').get(0);
        const { left: menuInnerLeft } = menuInner.getBoundingClientRect();
        tl = gsap.timeline({
            paused: true,
            onStart() {
                Dispatch.emit(MENU_OPEN);
                lockTabbing(el, $toggle.get(0));
            },
            onReverseComplete() {
                $toggle.attr({ 'aria-expanded': 'false' });
                $menu.get(0).hidden = true;
                Dispatch.emit(MENU_CLOSE);
                unlockTabbing();
                if (isLarge()) {
                    showNextPanel();
                }
                maybePreloadPanelImage();
                $('body').removeClass('menu-open');
                destroyOpenCloseTl();
                gsap.set([backdrop, menuWrapper, panelWrapper].filter(node => !!node), { clearProps: 'transform,opacity' });
            }
        })
            .set(menuWrapper, { opacity: 1 }, 0)
            .fromTo(backdrop, { opacity: 0 }, {
                opacity: 1,
                duration: 0.5
            }, 0)
            .fromTo(menuWrapper, { x: isLarge() ? Viewport.width - menuInnerLeft : Viewport.width }, {
                x: 0,
                duration: 1.2,
                ease: 'Cubic.easeInOut'
            }, 0);
        if (panelWrapper && panelWrapper.offsetParent !== null) {
            tl
                .fromTo(panelWrapper, { x: menuInnerLeft }, {
                    x: 0,
                    duration: 1.2,
                    ease: 'Cubic.easeInOut'
                }, 0);
        }
        tl.progress(progress);
    };

    const toggleDots = $toggle.find('[data-toggle-dots]').get(0);
    const toggleClose = $toggle.find('[data-toggle-close]').get(0);

    const open = () => {
        if (isOpen) {
            return;
        }
        isOpen = true;
        if (!getScrollbarWidth()) {
            // Only lock the scroll if there's no... scrollbar. Because otherwise the layout shifts in Safari :/
            lockScroll();
        }
        $('body')
            .addClass('menu-open');
        $toggle.attr({ 'aria-expanded': 'true' });
        $menu.get(0).hidden = false;
        if (!tl) {
            createTl();
        }
        if (isLarge()) {
            tl.timeScale(1).play();
        } else {
            tl.timeScale(1.3).play();
        }
        gsap.timeline()
            .to(toggleDots, { scale: 0, duration: 0.3 }, 0)
            .fromTo(toggleClose, { scale: 0 }, { scale: 1, duration: 0.3 }, 0);
    };

    const close = (tween = true) => {
        if (!isOpen) {
            return;
        }
        isOpen = false;
        unlockScroll();
        if (!tl) {
            gsap.set(toggleDots, { scale: 1 });
            gsap.set(toggleClose, { scale: 0 });
            createTl();
        }
        gsap.timeline()
            .to(toggleDots, { scale: 1, duration: 0.3 }, 0)
            .to(toggleClose, { scale: 0, duration: 0.3 }, 0);
        if (!tween) {
            tl.pause(0, false);
            return;
        }
        tl.timeScale(1.25).reverse();
    };

    const toggle = () => {
        if (!isOpen) {
            open();
        } else {
            close();
        }
    };

    $toggle.on('click', e => {
        e.preventDefault();
        toggle();
    });

    $toggle.on('mouseover', () => {
        maybePreloadPanelImage();
    });

    const onResize = () => {
        if (!tl) {
            return;
        }
        createTl();
        if (isOpen) {
            tl.play();
        } else {
            tl.reverse();
        }
    };

    Viewport.on('resize', onResize);

    const onScrollLocked = () => {
        if (getScrollbarWidth()) {
            $el.css('paddingRight', `${getScrollbarWidth()}px`);
        }
    };

    const onScrollUnlocked = () => {
        $el.css('paddingRight', '');
    };

    Dispatch.on(SCROLL_LOCKED, onScrollLocked);
    Dispatch.on(SCROLL_UNLOCKED, onScrollUnlocked);

    const onBodyKeyUp = e => {
        if (isOpen && isKey(e, 'Escape')) {
            close();
        }
    };

    $(el).on('lazybeforesizes', e => {
        const { width, height } = Viewport;
        e.detail.width = Math.max(width, height);
    });

    $('body').on('keyup', onBodyKeyUp);

    return {
        destroy() {
            $toggle.off('click mouseover');
            Viewport.off('resize', onResize);
            Dispatch.off(SCROLL_LOCKED, onScrollLocked);
            Dispatch.off(SCROLL_UNLOCKED, onScrollUnlocked);
            $('body').off('keyup', onBodyKeyUp);
            close(false);
        }
    };

};
