import gsap from 'gsap';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { DOM_CHANGED } from '../lib/events';

const VIDEO_TIMEOUT = 1000;

export default (el, {
    sources,
    useObserver = true
}) => {

    const $el = $(el);
    const video = $el.find('video')
        .get(0);
    const playButton = $el.find('button')
        .get(0);
    const image = $el.find('img[hidden],picture[hidden]')
        .get(0);

    let isPlaying = false;

    let observer;
    let timer;

    const show = () => {
        gsap.to(el, {
            opacity: 1,
            duration: 0.3
        });
    };

    const clearTimer = () => {
        if (!timer) {
            return;
        }
        clearTimeout(timer);
        timer = null;
    };

    const cantPlay = () => {
        clearTimer();
        if (playButton) {
            playButton.hidden = false;
        } else if (image) {
            image.hidden = false;
        }
        show();
    };

    const onTimeout = () => {
        if (playButton) {
            playButton.hidden = false;
        } else if (image) {
            image.hidden = false;
        }
        show();
        console.log('timeout', {
            playButton,
            image
        });
    };

    const onPlaying = () => {
        if (isPlaying) {
            return;
        }
        isPlaying = true;
        clearTimer();
        show();
        if (playButton) {
            gsap.to(playButton, {
                opacity: 0,
                duration: 0.3,
                onComplete() {
                    playButton.hidden = true;
                }
            });
        }
        console.log('playing', {
            playButton,
            image
        });
    };

    const setTimer = () => {
        clearTimer();
        timer = setTimeout(onTimeout, VIDEO_TIMEOUT);
    };

    const onTimeUpdate = e => {
        const { currentTime } = e.target;
        if (currentTime && currentTime > 0.001) {
            onPlaying();
        }
    };

    const onLoadStart = e => {
        if (!timer) {
            return;
        }
        setTimer();
    };

    const playAndCatch = () => {
        try {
            const promise = video.play();
            if (promise !== undefined) {
                promise
                    .then(clearTimer)
                    .catch(e => {
                        if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
                            cantPlay();
                        }
                    });
            }
        } catch (error) {
            console.error(error);
            cantPlay();
        }
    };

    const swapSource = ({ src }) => {
        if ($(video)
            .attr('src') === src) {
            return;
        }
        $(video)
            .attr('src', src);
        playAndCatch();
    };

    video.addEventListener('loadstart', onLoadStart);
    video.addEventListener('loadedmetadata', onLoadStart);
    video.addEventListener('loadeddata', onLoadStart);
    video.addEventListener('canplay', onLoadStart);
    video.addEventListener('timeupdate', onTimeUpdate);

    if (playButton) {
        playButton.addEventListener('click', playAndCatch);
    }

    const bootVideo = () => {

        if (sources.length > 1) {

            sources.forEach(source => {
                const mq = window.matchMedia(source.media);
                const onChange = e => {
                    if (e.matches) {
                        swapSource(source);
                    }
                };
                try {
                    mq.addEventListener('change', onChange);
                } catch (error) {
                    mq.addListener(onChange);
                }
                // Sets initial breakpoint
                if (mq.matches) {
                    swapSource(source);
                }
            });

        } else {

            swapSource(sources[0]);

        }

        setTimer();

    };

    const init = () => {

        if (!video.canPlayType || !video.canPlayType('video/mp4')) {
            cantPlay();
            return;
        }

        if (useObserver) {
            observer = new IntersectionObserver(([{ isIntersecting }]) => {
                if (!isIntersecting) {
                    return;
                }
                bootVideo();
                observer.disconnect();
                observer = null;
            });
            observer.observe(el);
        } else {
            bootVideo();
        }
    };

    if (ENV !== 'production') {
        Dispatch.emit(DOM_CHANGED);
    }

    return {
        init,
        destroy() {
            clearTimer();
            if (observer) {
                observer.disconnect();
                observer = null;
            }
            video.removeEventListener('loadstart', onLoadStart);
            video.removeEventListener('loadedmetadata', onLoadStart);
            video.removeEventListener('loadeddata', onLoadStart);
            video.removeEventListener('canplay', onLoadStart);
            video.removeEventListener('timeupdate', onTimeUpdate);
            if (playButton) {
                playButton.removeEventListener('click', playAndCatch);
            }
        }
    };

};
