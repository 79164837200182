import gsap from 'gsap';
import debounce from 'lodash/debounce';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import { clamp, isTouch } from '../lib/helpers';
import Dispatch from '../core/Dispatch';
import { DOM_CHANGED, FONTS_LOADED } from '../lib/events';

export default el => {

    let THREE = null;

    const $el = $(el);
    const texts = $el.find('[data-text]')
        .get();
    const canvasContainer = $el.find('[data-canvas]')
        .get(0);

    const {
        model: glbPath,
        equirectangular: equirectangularUrl
    } = el.dataset;

    const ts = new Date().getTime();

    let scene;
    let camera;
    let orbitObject;
    let renderer;
    let loader;
    let brick;
    let tl;

    let isVisible = true;
    let observer = null;

    const getViewportDimensions = () => {
        // Avoid very extreme widescreen formats
        const { width } = Viewport;
        let { height } = Viewport;
        let ratio = width / height;
        if (ratio >= 2.5) {
            ratio = 2.5;
            height = Math.ceil(width / ratio);
        }
        return { width, height };
    };

    let { width: viewportWidth, height: viewportHeight } = getViewportDimensions();

    const getCameraZFromBreakpoint = () => {
        const { name: breakpoint } = Viewport.breakpoint;
        if (['l', 'lp', 'xl'].indexOf(breakpoint) > -1) {
            return 16;
        }
        return 22;
    };

    const visibleHeightAtZDepth = baseDepth => {
        // compensate for cameras not positioned at z=0
        let depth = baseDepth;
        const cameraOffset = camera.position.z;
        if (depth < cameraOffset) {
            depth -= cameraOffset;
        } else {
            depth += cameraOffset;
        }

        // vertical fov in radians
        const vFOV = camera.fov * (Math.PI / 180);

        // Math.abs to ensure the result is always positive
        return 2 * Math.tan(vFOV / 2) * Math.abs(depth);
    };

    const visibleWidthAtZDepth = (depth = 0) => {
        const height = visibleHeightAtZDepth(depth);
        const aspect = clamp(camera.aspect, 3 / 4, 16 / 9);
        return height * aspect;
    };

    const render = () => {
        if (renderer && isVisible) {
            renderer.render(scene, camera);
        }
    };

    const setProgress = (immediate = false) => {

        if (!tl) {
            return;
        }

        const {
            top,
            height
        } = el.getBoundingClientRect();

        const progress = clamp(((top - viewportHeight) * -1) / (height * 1.1), 0, 1);

        if (immediate) {
            gsap.set(tl, { progress });
        } else {
            gsap.to(tl, {
                progress,
                duration: 1,
                ease: 'Expo.easeOut'
            });
        }
    };

    const createTl = () => {

        if (!brick) {
            return;
        }

        if (tl) {
            tl.kill();
        }

        tl = gsap.timeline({
            paused: true
        })
            .fromTo(camera.position, { x: -visibleWidthAtZDepth() }, {
                x: visibleWidthAtZDepth(),
                duration: 1,
                ease: 'none'
            }, 0)
            .fromTo(brick.rotation, {
                x: 4.1,
                y: 0.9,
                z: -1.7
            }, {
                x: -0.3,
                y: 0,
                z: 0,
                duration: 1,
                ease: 'none'
            }, 0);

        setProgress(true);
    };

    const doInit = () => {
        if (!THREE || !THREE.GLTFLoader) {
            console.warn('Can\'t load; missing Three.js runtime');
            return;
        }

        // Create the scene
        scene = new THREE.Scene();

        camera = new THREE.PerspectiveCamera(30, viewportWidth / viewportHeight, 1, 100);
        camera.position.z = getCameraZFromBreakpoint();

        renderer = new THREE.WebGLRenderer({
            alpha: true,
            antialias: true
        });

        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(viewportWidth, viewportHeight);
        renderer.outputEncoding = THREE.sRGBEncoding;
        renderer.toneMapping = THREE.ACESFilmicToneMapping;
        renderer.toneMappingExposure = 0.75;
        renderer.setAnimationLoop(render);

        canvasContainer.appendChild(renderer.domElement);

        // Load the model
        loader = new THREE.GLTFLoader();
        loader.load(glbPath, gltf => {

            let material;
            if (equirectangularUrl) {
                material = new THREE.MeshBasicMaterial({
                    side: THREE.FrontSide
                });
            } else {
                // Fallback for when there is no reflective image
                material = new THREE.MeshStandardMaterial({
                    side: THREE.FrontSide,
                    color: 0xB97DFF,
                    roughness: 0.2,
                    metalness: 1
                });
                const light1 = new THREE.DirectionalLight(0xffffff, 1, 0);
                light1.position.set(0, 200, 0);
                scene.add(light1);
                const light2 = new THREE.DirectionalLight(0xffffff, 1, 0);
                light2.position.set(200, 0, 0);
                scene.add(light2);
            }

            [brick] = gltf.scene.children;
            brick.material = material;

            scene.add(brick);

            orbitObject = new THREE.Object3D();
            orbitObject.rotation.order = 'YXZ'; // This is important to keep level, so Z should be the last axis to rotate in order
            orbitObject.position.copy(brick.position);
            scene.add(orbitObject);
            orbitObject.add(camera);

            // REFLECTION MAP
            if (equirectangularUrl) {
                const textureLoader = new THREE.TextureLoader();
                textureLoader.load(equirectangularUrl, equirectangularTexture => {
                    const texture = equirectangularTexture;
                    texture.encoding = THREE.sRGBEncoding;
                    texture.mapping = THREE.EquirectangularReflectionMapping;
                    material.envMap = texture;
                    setTimeout(() => {
                        gsap.fromTo(canvasContainer, { opacity: 0 }, {
                            opacity: 1,
                            duration: 0.3
                        });
                        createTl();
                    }, 0);
                });
            } else {
                gsap.fromTo(canvasContainer, { opacity: 0 }, {
                    opacity: 1,
                    duration: 0.3
                });
                createTl();
            }

        }, undefined, error => {
            console.error(error);
        });
    };

    const resizeHandler = () => {
        if (texts.length > 1) {
            const [topText, bottomText] = texts;
            topText.style.height = '';
            bottomText.style.height = '';
            const { height: topTextHeight } = topText.firstElementChild.getBoundingClientRect();
            const { height: bottomTextHeight } = bottomText.firstElementChild.getBoundingClientRect();
            const textHeight = Math.max(viewportHeight, topTextHeight, bottomTextHeight);
            topText.style.height = `${textHeight}px`;
            bottomText.style.height = `${textHeight}px`;
            // Give the bottom text some extra padding to avoid it overlapping the sticky top text
            const bottomTextPaddingTop = Math.max(topTextHeight, viewportHeight - (topTextHeight + bottomTextHeight));
            bottomText.style.paddingTop = `${bottomTextPaddingTop}px`;
        }
        el.style.height = '';
        el.style.height = `${Math.round(el.getBoundingClientRect().height)}px`;
        canvasContainer.style.width = `${viewportWidth}px`;
        canvasContainer.style.height = `${viewportHeight}px`;
        if (renderer) {
            camera.aspect = viewportWidth / viewportHeight;
            camera.position.z = getCameraZFromBreakpoint();
            camera.updateProjectionMatrix();
            renderer.setSize(viewportWidth, viewportHeight);
            createTl();
        }
    };

    const debouncedResizeHandler = debounce(resizeHandler, 10, {
        leading: false,
        trailing: true
    });

    const onResize = () => {
        // Don't resize if the viewport dimensions didn't change at
        // or if this looks like a touch device, the width *didn't* change, and the height didn't change by more than at least 150px
        // Don't resize if the device is touch (i.e. likely a phone), the viewport width didn't change and the viewport height changed by less than 150px
        const { width: newViewportWidth, height: newViewportHeight } = getViewportDimensions();
        const widthChanged = newViewportWidth !== viewportWidth;
        const heightChanged = newViewportHeight !== viewportHeight;
        if ((!widthChanged && !heightChanged) || (isTouch() && !widthChanged && Math.abs(viewportHeight - newViewportHeight) < 150)) {
            return;
        }
        // Cache the currently used dimensions
        viewportWidth = newViewportWidth;
        viewportHeight = newViewportHeight;
        debouncedResizeHandler();
    };

    const onScroll = () => {
        setProgress();
    };

    const orbitValues = {
        x: 0,
        y: 0
    };

    const onMouseMove = e => {
        if (!isVisible || !orbitObject || isTouch()) {
            return;
        }
        gsap.killTweensOf(orbitValues);
        gsap.to(orbitValues, {
            x: e.movementY * 0.0002,
            y: e.movementX * 0.0002,
            duration: 1,
            ease: 'Expo.easeOut',
            onUpdate() {
                orbitObject.rotation.y = clamp(orbitObject.rotation.y + orbitValues.y, -0.3, 0.3);
                orbitObject.rotation.x = clamp(orbitObject.rotation.x + orbitValues.x, -0.3, 0.3);
                orbitObject.rotation.z = 0; // This is important to keep the camera level
            }
        });
    };

    const onMouseLeave = () => {
        if (!orbitObject || isTouch()) {
            return;
        }
        gsap.killTweensOf(orbitValues);
        gsap.to(orbitValues, {
            x: 0,
            y: 0,
            duration: 1,
            ease: 'Expo.easeOut',
            onUpdate() {
                orbitObject.rotation.y = clamp(orbitObject.rotation.y + orbitValues.y, -0.3, 0.3);
                orbitObject.rotation.x = clamp(orbitObject.rotation.x + orbitValues.x, -0.3, 0.3);
                orbitObject.rotation.z = 0; // This is important to keep the camera level
            }
        });
        onMouseMove({
            movementX: 0,
            movementY: 0
        });
    };

    const pollForThree = () => {
        THREE = window.THREE || null;
        if ((!THREE || !THREE.GLTFLoader) && (new Date().getTime()) - ts < 4000) {
            setTimeout(pollForThree, 10);
            return;
        }
        resizeHandler();
        doInit();
    };

    let isFirst = true;

    const init = () => {
        observer = new IntersectionObserver(([{ isIntersecting }]) => {
            const wasVisible = isVisible;
            isVisible = isIntersecting;
            if (isVisible && isFirst) {
                isFirst = false;
                Dispatch.on(FONTS_LOADED, () => {
                    pollForThree();
                }, true);
            }
            if (isVisible !== wasVisible) {
                canvasContainer.style.visibility = isVisible ? '' : 'hidden';
            }
        });
        observer.observe(el);
        window.addEventListener('mousemove', onMouseMove);
        el.addEventListener('mouseleave', onMouseLeave);
        Viewport.on('scroll', onScroll);
        Viewport.on('resize', onResize);
    };

    const destroy = () => {
        window.removeEventListener('mousemove', onMouseMove);
        el.removeEventListener('mouseleave', onMouseLeave);
        Viewport.off('scroll', onScroll);
        Viewport.off('resize', onResize);
        if (tl) {
            tl.kill();
            tl = null;
        }
        if (observer) {
            observer.disconnect();
            observer = null;
        }
        scene = null;
        camera = null;
        renderer = null;
    };

    if (ENV !== 'production') {
        Dispatch.emit(DOM_CHANGED);
    }

    return {
        init,
        destroy
    };

};
