import gsap from 'gsap';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Viewport from '../core/Viewport';

import Slideshow from './Slideshow';

import { MODAL_OPEN, MODAL_CLOSE, DOM_CHANGED } from '../lib/events';
import { lockTabbing, unlockTabbing } from '../lib/helpers';

export default el => {

    const $el = $(el);

    let activeHotspot = null;

    const initPopup = popup => {
        const slideshow = popup.querySelector('[data-slideshow]');
        if (!slideshow) {
            return;
        }
        const flkty = $(slideshow).data('_slideshow');
        if (!flkty) {
            Slideshow(slideshow, { autoPlay: 3000 });
        } else {
            flkty.select(0, true, true);
            flkty.playPlayer();
            flkty.resize();
        }
        const popupEl = $(popup).find('[data-popup]').get(0);
        const closeBtn = $(popup).find('button[data-close]').get(0);
        gsap.timeline()
            .fromTo(popup, { opacity: 0 }, { opacity: 1, duration: 0.5 }, 0)
            .fromTo(closeBtn, { scale: 0 }, { scale: 1, duration: 0.3 }, 0)
            .fromTo(popupEl, { opacity: 0 }, { opacity: 1, duration: 0.3 }, 0.15)
            .fromTo(popupEl, { y: 20 }, { y: 0, duration: 0.5, ease: 'Cubic.easeOut' }, 0.15);
    };

    const collapseActive = () => {
        if (!activeHotspot) {
            return;
        }
        const oldActiveHotspot = activeHotspot;
        const popup = oldActiveHotspot.nextElementSibling;
        const closeBtn = $(popup).find('button[data-close]').get(0);
        oldActiveHotspot.setAttribute('aria-expanded', 'false');
        gsap.timeline({
            onComplete() {
                popup.hidden = true;
                gsap.set([popup, closeBtn], { clearProps: 'all' });
            }
        })
            .to(popup, { opacity: 0, duration: 0.3 }, 0)
            .to(closeBtn, { scale: 0, duration: 0.3 }, 0);
        activeHotspot = null;
        unlockTabbing();
        Dispatch.emit(MODAL_CLOSE);
    };

    const setActive = hotspot => {
        if (activeHotspot === hotspot) {
            return;
        }
        collapseActive();
        activeHotspot = hotspot;
        activeHotspot.setAttribute('aria-expanded', 'true');
        const popup = activeHotspot.nextElementSibling;
        popup.hidden = false;
        lockTabbing(popup, $(popup).find('button[data-close]').get(0));
        initPopup(popup);
        const closeBtn = $(popup).find('button[data-close]').get(0);
        gsap.timeline()
            .fromTo(popup, { opacity: 0 }, { opacity: 1, duration: 0.3 }, 0)
            .fromTo($(popup).find('[data-popup]').get(0), { y: -10 }, { y: 0, duration: 0.5, ease: 'Quint.easeOut' }, 0)
            .fromTo(closeBtn, { scale: 0 }, { scale: 1, duration: 0.3 }, 0);
        Dispatch.emit(MODAL_OPEN);
    };

    const onHotspotClick = e => {
        const { triggerTarget: hotspot } = e;
        if (hotspot !== activeHotspot) {
            setActive(hotspot);
        } else {
            collapseActive();
        }
    };

    const onPopupCloseBtnClick = e => {
        const { triggerTarget: closeBtn } = e;
        const hotspot = document.getElementById(closeBtn.dataset.close);
        if (!hotspot || hotspot !== activeHotspot) {
            return;
        }
        collapseActive();
    };

    $el
        .on('click', 'button[aria-expanded]', onHotspotClick)
        .on('click', 'button[data-close]', onPopupCloseBtnClick);

    const $body = $('body');

    const onBodyClick = e => {
        if (!activeHotspot) {
            return;
        }
        if (e.target === activeHotspot) {
            return;
        }
        const popup = activeHotspot.nextElementSibling.querySelector('[data-popup]');
        if (e.target === popup || popup.contains(e.target)) {
            return;
        }
        collapseActive();
    };

    const onBodyKeyUp = e => {
        if (!activeHotspot) {
            return;
        }
        if (e.key === 'Escape' || e.keyCode === 27) {
            e.stopImmediatePropagation();
            collapseActive();
        }
    };

    $body
        .on('click', onBodyClick)
        .on('keyup', onBodyKeyUp);

    const onResize = () => {
        if (!activeHotspot) {
            return;
        }
        // TODO only set scrolltop if the active hotspot is too far away from the center
        window.scrollTo(0, $(activeHotspot).offset().top - (Viewport.height / 2));
    };

    Viewport.on('resize', onResize);

    if (ENV !== 'production') {
        Dispatch.emit(DOM_CHANGED);
    }

    return {
        destroy() {
            collapseActive();
            $el.off('click');
            $body.off('click');
            Viewport.off('resize', onResize);
        }
    };

};
