import gsap from 'gsap';
import $ from '../../core/Dom';
import Viewport from '../../core/Viewport';
import Dispatch from '../../core/Dispatch';
import { FONTS_LOADED } from '../../lib/events';
import { clearTimelineProps } from '../../lib/helpers';

export default el => {

    const $el = $(el);

    const gif = $el.find('[data-gif]').get(0);
    const bubblesContainer = $el.find('[data-bubbles]').get(0);
    const bubbles = Array.from(bubblesContainer.children);

    let tl;
    let gifObserver;
    let bubblesObserver;

    let gifVisible;
    let bubblesVisible;
    let isPlaying = false;

    const sizeMultilineBubbles = () => {
        if (!bubbles.length) {
            return;
        }
        gsap.set(bubbles, { clearProps: 'width' });
        const minBubbleHeight = bubbles.reduce((carry, bubble) => {
            const { height } = bubble.getBoundingClientRect();
            if (carry === null || height < carry) {
                return height;
            }
            return carry;
        }, null);
        const bubblePadding = parseInt(window.getComputedStyle(bubbles[0].firstElementChild).paddingLeft.replace('px', ''), 10);
        bubbles.forEach(bubble => {
            if (!bubble.hasAttribute('data-size')) {
                return;
            }
            const { height } = bubble.getBoundingClientRect();
            if (height <= minBubbleHeight) {
                // Single line bubble
                gsap.set(bubble.firstElementChild, { clearProps: 'borderRadius' });
            } else {
                // Multi-line bubble
                const { left: bubbleLeft } = bubble.firstElementChild.getBoundingClientRect();
                const maxRightBound = Array.from(bubble.firstElementChild.children).reduce((carry, span) => {
                    const { width, left } = span.getBoundingClientRect();
                    return Math.max(carry, width + (left - (bubbleLeft + bubblePadding)));
                }, 0);
                const multilineRadiusByBreakpoint = {
                    lp: 35,
                    xl: 40
                };
                gsap.set(bubble.firstElementChild, { borderRadius: multilineRadiusByBreakpoint[Viewport.breakpoint.name] || 30 });
                gsap.set(bubble, { width: Math.ceil(maxRightBound + (bubblePadding * 2)) });
            }
        });
    };

    const createTl = () => {

        let progress = 0;

        if (tl) {
            progress = tl.totalProgress();
            tl.pause(0, false);
            clearTimelineProps(tl);
            tl.kill();
        }

        sizeMultilineBubbles();

        tl = gsap.timeline({ paused: true })
            .set(el, { opacity: 1 })
            .fromTo(gif.firstElementChild, { xPercent: 100 }, {
                xPercent: 0,
                duration: 1,
                ease: 'Quint.easeOut'
            });

        const {
            height: bubbleContainerHeight,
            top: bubbleContainerTop
        } = bubblesContainer.getBoundingClientRect();

        const bubblesTl = gsap.timeline();

        bubbles.forEach((bubble, i) => {

            const {
                width: bubbleWidth,
                height: bubbleHeight,
                top: bubbleTop
            } = bubble.getBoundingClientRect();

            const dots = $(bubble)
                .find('[data-dots]')
                .get(0);

            let fromY = bubbleContainerHeight + (bubbleContainerTop - bubbleTop);

            let dotsHeight = dots ? dots.getBoundingClientRect().height : 0;
            if (dotsHeight) {
                dotsHeight += parseInt(window.getComputedStyle(bubble).marginBottom, 10);
                fromY -= Math.max(0, bubbleHeight - dotsHeight);
            }

            const toY = (bubbleContainerHeight - bubbleHeight) + (bubbleContainerTop - bubbleTop);

            const bubbleTl = gsap.timeline()
                .fromTo(bubble, { opacity: 0 }, {
                    opacity: 1,
                    duration: 0.3,
                    ease: 'Cubic.easeIn'
                }, 0.1)
                .fromTo(bubble, {
                    x: 30,
                    transformOrigin: 'right bottom'
                }, {
                    x: 0,
                    ease: 'Back.easeInOut',
                    transformOrigin: 'right bottom',
                    duration: 0.5
                }, 0)
                .fromTo(bubble, {
                    rotation: -25,
                    transformOrigin: 'right bottom'
                }, {
                    rotation: 0,
                    ease: 'Back.easeInOut',
                    transformOrigin: 'right bottom',
                    duration: 0.5
                }, 0)
                .fromTo(bubble, {
                    scale: 0.3,
                    transformOrigin: 'right bottom'
                }, {
                    scale: 1,
                    ease: 'Back.easeInOut',
                    transformOrigin: 'right bottom',
                    duration: 0.5
                }, 0)
                .fromTo(bubble, { y: fromY }, {
                    y: toY,
                    ease: 'Back.easeInOut',
                    duration: 0.5
                }, 0);

            if (dots) {
                const content = bubble.firstElementChild;
                const { borderRadius } = window.getComputedStyle(content);
                const dotsContainer = dots.firstElementChild;
                const dotGraphics = dotsContainer.firstElementChild;
                const thoughtBubbles = dotGraphics.nextElementSibling;
                bubbleTl
                    .set(bubble, { pointerEvents: 'none' })
                    .to(dotsContainer, {
                        borderRadius,
                        duration: 0.5,
                        ease: 'Back.easeInOut'
                    }, 'dots+=0.6')
                    .to(dotsContainer, {
                        width: bubbleWidth,
                        height: bubbleHeight,
                        duration: 0.5,
                        ease: 'Back.easeInOut'
                    }, 'dots+=0.6')
                    .to(dotGraphics, {
                        scale: 0,
                        opacity: 0,
                        duration: 0.3
                    }, 'dots+=0.6')
                    .to(Array.from(thoughtBubbles.children).reverse(), {
                        scale: 0,
                        yPercent: -100,
                        xPercent: -100,
                        duration: 0.3,
                        stagger: 0.05,
                        ease: 'Back.easeIn'
                    }, 'dots+=0.65')
                    .to(content, {
                        opacity: 1,
                        duration: 0.3
                    }, 'content-=0.15')
                    .set(bubble, { clearProps: 'pointerEvents' }, 'content-=0.15')
                    .set(dotsContainer, { opacity: 0 });
            }

            for (let j = 0; j < (bubbles.length - (bubbles.length - i)); j += 1) {
                bubbleTl.to(bubbles[j], {
                    y: dotsHeight ? `-=${dotsHeight}px` : toY,
                    duration: 0.5,
                    ease: 'Back.easeInOut'
                }, 0);
                bubbleTl.to(bubbles[j], {
                    y: toY,
                    duration: 0.5,
                    ease: 'Back.easeInOut'
                }, 'dots+=0.6');

            }

            bubblesTl.add(bubbleTl, '-=0.2');

        });

        tl.timeScale(0.75);
        tl.add(bubblesTl, '-=0.35');

        if (progress) {
            tl.totalProgress(progress);
            tl.play();
        }

    };

    const onBreakpoint = () => {
        if (!tl) {
            return;
        }
        createTl();
    };

    const onObserve = () => {
        isPlaying = isPlaying || !!$el.find('[data-component="AudioPlayer"].is-playing').get(0);
        if (gifVisible) {
            tl.play();
        } else if (!isPlaying && !bubblesVisible) {
            tl.pause(0);
        }
    };

    const create = () => {

        if (tl) {
            return;
        }

        createTl();

        gifObserver = new IntersectionObserver(([{ isIntersecting }]) => {
            isPlaying = isPlaying || !!$el.find('[data-component="AudioPlayer"].is-playing').get(0);
            gifVisible = isIntersecting;
            onObserve();
        }, {
            threshold: 1,
            rootMargin: '10% 0% 10% 0%'
        });

        gifObserver.observe(gif);

        bubblesObserver = new IntersectionObserver(([{ isIntersecting }]) => {
            bubblesVisible = isIntersecting;
            onObserve();
        }, {
            threshold: 0
        });

        bubblesObserver.observe(bubblesContainer);

    };

    const init = () => {
        requestAnimationFrame(create);
    };

    Dispatch.on(FONTS_LOADED, init, true);

    Viewport.on('breakpoint', onBreakpoint);

    const destroy = () => {
        if (gifObserver) {
            gifObserver.disconnect();
            gifObserver = null;
        }
        if (bubblesObserver) {
            bubblesObserver.disconnect();
            bubblesObserver = null;
        }
        Dispatch.off(FONTS_LOADED, init);
        Viewport.off('breakpoint', onBreakpoint);
    };

    return {
        destroy
    };

};
