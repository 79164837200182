import gsap from 'gsap';
import $ from '../../core/Dom';
import superagent from '../../core/request';
import Components from '../../core/Components';
import Dispatch from '../../core/Dispatch';
import { AJAX_LOAD_END, AJAX_LOAD_START, DOM_CHANGED } from '../../lib/events';
import { trackPageView } from '../../modules/Tracking';

export default el => {

    const $el = $(el);

    let isSubmitting = false;

    const updateHtml = html => {
        const $html = $('<div/>').html(html).find('#upgrape-content').eq(0);
        const $container = $('#upgrape-content').eq(0);
        gsap.timeline({
            onComplete() {
                Components.destroy($container.get(0));
                $container.html($html.html());
                Components.init($container.get(0));
                gsap.set($container.get(0), { clearProps: 'opacity' });
                Dispatch.emit(DOM_CHANGED);
            }
        })
            .to($container.get(0), {
                opacity: 0,
                duration: 0.3,
                ease: 'Quad.easeIn'
            });
    };

    const onSubmit = e => {
        e.preventDefault();
        if (isSubmitting) {
            return;
        }
        isSubmitting = true;
        const submit = $el.find('button[type="submit"]').get(0);
        submit.classList.toggle('is-active');

        const { csrfTokenName, csrfTokenValue } = window;

        Dispatch.emit(AJAX_LOAD_START);

        superagent
            .post(window.location.href)
            .type('form')
            .set('Accept', 'application/json')
            .send({
                action: $el.find('input[name="action"]').val(),
                [csrfTokenName]: csrfTokenValue
            })
            .then(res => {
                const { status, body } = res;
                if (status !== 200) {
                    throw new Error();
                }
                if (body.redirect && body.redirect !== window.location.href) {
                    window.history.pushState(null, '', body.redirect);
                }
                trackPageView(body.redirect || window.location.href);
                return superagent.get(body.redirect || window.location.href);
            })
            .catch(error => {
                console.warn(error);
            })
            .then(({ text, status }) => {
                if (status !== 200 || !text) {
                    throw new Error();
                }
                updateHtml(text);
            })
            .catch(error => {
                console.error(error);
            })
            .then(() => {
                isSubmitting = false;
                Dispatch.emit(AJAX_LOAD_END);
            });
    };

    $el.on('submit', onSubmit);

    return {
        destroy() {
            $el.off('submit');
        }
    };

};
