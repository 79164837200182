import Flickity from 'flickity';
import $ from '../core/Dom';
// import Dispatch from '../core/Dispatch';
// import { DOM_CHANGED } from '../lib/events';

export default el => {

    const $el = $(el);
    const $slider = $el.find('[data-slider]').eq(0);

    let flkty;

    const destroyFlickity = () => {
        if (!flkty) {
            return;
        }
        flkty.destroy();
        flkty = null;
    };

    const createFlickity = () => {
        destroyFlickity();
        flkty = new Flickity($slider.get(0), {
            contain: true,
            dragThreshold: 10,
            cellAlign: 'left',
            prevNextButtons: false,
            pageDots: false,
            freeScroll: true,
            freeScrollFriction: 0.045,
            resize: true,
            adaptiveHeight: false,
            setGallerySize: true,
            wrapAround: false,
            accessibility: true,
            on: {
                dragStart() {
                    document.ontouchmove = e => e.preventDefault();
                },
                dragEnd() {
                    document.ontouchmove = () => true;
                },
                scroll() {
                    //Dispatch.emit(DOM_CHANGED);
                }
            }
        });
    };

    let observer;

    const init = () => {
        observer = new IntersectionObserver(([{ isIntersecting }]) => {
            if (!isIntersecting) {
                return;
            }
            $el.find('img.lazyload').addClass('lazypreload');
            observer.disconnect();
            observer = null;
        });
        observer.observe(el);
        createFlickity();
    };

    const destroy = () => {
        destroyFlickity();
        if (observer) {
            observer.disconnect();
            observer = null;
        }
    };

    return {
        init,
        destroy
    };

};
