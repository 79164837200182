import gsap from 'gsap';
import $ from '../../core/Dom';
import Dispatch from '../../core/Dispatch';
import { DOM_CHANGED } from '../../lib/events';
import { trackPageView } from '../../modules/Tracking';
import Config from '../../core/Config';
import Viewport from '../../core/Viewport';

export default el => {

    const $el = $(el);

    const $items = $el.find('a[data-item]');
    const $tags = $el.find('a[data-tag]');

    let mouseEnteredTag = null;

    const expandTag = tag => {
        const chars = $(tag).find('[data-char]').get();
        gsap.timeline()
            .to(tag.firstElementChild, { width: 'auto', duration: 0.3 }, 0)
            .to(chars, { opacity: 1, stagger: 0.01, duration: 0.3 }, 0);
    };

    const collapseTag = tag => {
        const chars = $(tag).find('[data-char]').get();
        const { width: currWidth } = tag.firstElementChild.getBoundingClientRect();
        gsap.set(tag.firstElementChild, { clearProps: 'width' });
        gsap.timeline()
            .fromTo(tag.firstElementChild, { width: currWidth }, { width: window.getComputedStyle(tag.firstElementChild).width, duration: 0.3 }, 0)
            .to(chars, { opacity: 0, duration: 0.15 }, 0)
            .set(tag.firstElementChild, { clearProps: 'width' });
    };

    const disableItem = item => {
        item.classList.add('disabled');
        item.removeAttribute('href');
        item.setAttribute('tabIndex', '-1');
        item.nextElementSibling.classList.add('disabled');
    };

    const enableItem = item => {
        item.classList.remove('disabled');
        item.nextElementSibling.classList.remove('disabled');
        item.setAttribute('href', item.dataset.href);
        item.removeAttribute('tabIndex');
    };

    const enableTag = tag => {
        tag.classList.remove('active', 'disabled');
        tag.setAttribute('href', tag.dataset.href);
        tag.removeAttribute('tabIndex');
    };

    const disableTag = tag => {
        tag.classList.remove('active');
        tag.classList.add('disabled');
        tag.setAttribute('tabIndex', -1);
        tag.removeAttribute('href');
    };

    const resetActiveTag = () => {
        $tags.each(tag => {
            enableTag(tag);
            if (tag !== mouseEnteredTag) {
                collapseTag(tag);
            }
        });
        $items.each(item => {
            enableItem(item);
        });
        document.title = 'Up:Grape';
        Viewport.initTabbing();
    };

    const setActiveTag = tagId => {
        $tags.each(tag => {
            const isActive = tag.dataset.tag === tagId;
            if (isActive) {
                gsap.set(tag.firstElementChild, { width: tag.firstElementChild.getBoundingClientRect().width });
                tag.classList.add('active');
                tag.setAttribute('href', Config.get('siteUrl'));
                tag.removeAttribute('tabIndex');
                expandTag(tag);
                document.title = `Up:Grape:${tag.textContent}`;
            } else {
                disableTag(tag);
                collapseTag(tag);
            }
        });
        $items.each(item => {
            const hasActiveTag = $(item).parent().find(`a[data-tag="${tagId}"]`).get().length;
            if (hasActiveTag) {
                enableItem(item);
            } else {
                disableItem(item);
            }
        });
        Viewport.initTabbing();
    };

    $tags.each(tag => {
        $(tag).on('mouseenter', () => {
            mouseEnteredTag = tag;
        });
        $(tag).on('mouseout', () => {
            if (mouseEnteredTag === tag) {
                mouseEnteredTag = null;
            }
        });
        $(tag).on('mouseover focusin', () => {
            expandTag(tag);
        });
        $(tag).on('mouseleave focusout', () => {
            if (mouseEnteredTag === tag || tag.classList.contains('active') || (document.documentElement.classList.contains('outline') && document.activeElement === tag)) {
                return;
            }
            collapseTag(tag);
        });
    });

    $tags.on('click', e => {

        e.preventDefault();
        e.stopImmediatePropagation();

        const { currentTarget: tag } = e;

        if (tag.classList.contains('disabled')) {
            return;
        }

        const { href: url } = tag;

        trackPageView(url);

        // If the tag was active, then just re-enable everything
        if (tag.classList.contains('active')) {
            resetActiveTag();
        } else {
            setActiveTag(tag.dataset.tag);
        }

        window.history.pushState(null, '', url);

    });

    if (ENV !== 'production') {
        Dispatch.emit(DOM_CHANGED);
    }

    return {
        destroy() {
            $tags.off('mouseenter mouseleave mouseover focusin focusout click');
        }
    };

};
